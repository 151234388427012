import { Record } from 'immutable';
import { SessionUser, MakeUserFromRuntime, } from 'src/models/SessionUser';
import { SessionRuntime, MakeFromRuntime } from 'src/models/SessionRuntime';
import LoadSessionAPI from 'src/api/LoadSession';
import SignUpAPI from 'src/api/SignUp';
import SignOutAPI from 'src/api/SignOut';
import VerifyUserByCodeAPI from 'src/api/VerifyUserByCode';
import SignInWithGoogleAPI from 'src/api/SignInWithGoogle';
import SendResetPasswordEmailAPI from 'src/api/SendResetPasswordEmail';
import ResetPasswordAPI from 'src/api/ResetPassword';
import UpdatePasswordAPI from 'src/api/UpdatePassword';
import SendUpdateEmailAPI from 'src/api/SendUpdateEmail';
import UpdateAccountAPI from 'src/api/UpdateAccount';
import DeleteAccountAPI from 'src/api/DeleteAccount';
import VerifyNewEmailAPI from 'src/api/VerifyNewEmail';
import { ErrorResponse } from 'src/utils/api';
import { showToast } from './toasts';
import EventEmitter from 'eventemitter3';
const Events = new EventEmitter()

interface Session {
    User:       SessionUser,
    Runtime:    SessionRuntime,
    CSRFToken:  string,
}

interface Account {
    name: string,
    email: string,
}

const SessionFactory = Record<Session>({
    User:       SessionUser(),
    Runtime:    SessionRuntime(),
    CSRFToken:  "",
});

type session = Record<Session>;
let session = SessionFactory();

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession():Promise<session> {
    try {
        const [ res, CSRFToken ] = await LoadSessionAPI();
        session = session.set("User", MakeUserFromRuntime(res.user))
        session = session.set("Runtime", MakeFromRuntime(res.runtime))
        if (CSRFToken != null) {
            session = session.set("CSRFToken", CSRFToken);
        }
    } catch (error) {
        console.error(error);        
    }
    return session;
}

export async function SignUp(email: string, password: string):Promise<ErrorResponse<any> | null> {
    try {
        const [ res, err ] = await SignUpAPI(email, password);
        if (err == null) {
            session = session.set("User", MakeUserFromRuntime(res.user))
        } else {
            return err
        }

        if (res.user.verified) {
            showToast("Successfully signed in");
        }
    } catch (error) {
        console.error(error);
    }

    Events.emit("sign-in");
    return null;
}

export async function SignOut() {
    await SignOutAPI();
    session = session.set("User", SessionUser())
    showToast("Successfully signed out");
    Events.emit("sign-out");
}

export async function VerifyByCode(code: string): Promise<boolean> {
    const verified = await VerifyUserByCodeAPI(code);
    if (verified) {
        session = session.setIn(["User", "verified"], true);
        showToast("Successfully verified account");
        Events.emit("sign-out");

        return true;
    } else {
        return false;
    }
}

export async function SendResetPasswordEmail(email: string): Promise<ErrorResponse<any> | null> {
    return await SendResetPasswordEmailAPI(email);
}

export async function UpdateEmail(email: string): Promise<ErrorResponse<any> | null> {
    return await SendUpdateEmailAPI(email);
}

export async function UpdateAccount(account: Account): Promise<ErrorResponse<any> | null> {
    return await UpdateAccountAPI(account);
}

export async function DeleteAccount(): Promise<ErrorResponse<any> | null> {
    return await DeleteAccountAPI();
}

export async function SetAccount(account: Account): Promise<ErrorResponse<any> | null> {
    session = session.setIn(["User", "full_name"], account.name);
    session = session.setIn(["User", "primary_email"], account.email);
    return null
}

export async function VerifyNewEmail(code: string): Promise<ErrorResponse<any> | null> {
    const [ email, err ] = await VerifyNewEmailAPI(code);
    if (err != null) {
        return err
    }

    session = session.setIn(["User", "primary_email"], email);
    showToast("Successfully verified new email");
    return null
}

export async function UpdatePassword(OldPassword: string, NewPassword: string): Promise<ErrorResponse<any> | null> {
    return await UpdatePasswordAPI(OldPassword, NewPassword);
}

export async function ResetPassword(code: string, password: string): Promise<ErrorResponse<any> | null> {
    return await ResetPasswordAPI(code, password);
}

export async function SignInWithGoogle(token: string): Promise<void> {
    try {
        const [ res, err ] = await SignInWithGoogleAPI(token);
        if (err == null) {
            session = session.set("User", MakeUserFromRuntime(res.user))
        } else {
            console.error(err);   
            return;
        }
    } catch (error) {
        console.error(error);        
    }

    showToast("Successfully signed in");
    Events.emit("sign-in");
    return
}

export function listenOnSignIn(func:() => void) {
    Events.addListener("sign-in", func)
}

export function unlistenOnSignIn(func:() => void) {
    Events.removeListener("sign-in", func)
}

export function listenOnSignOut(func:() => void) {
    Events.addListener("sign-out", func)
}

export function unlistenOnSignOut(func:() => void) {
    Events.removeListener("sign-out", func)
}

export { session };