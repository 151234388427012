import { StyleSheet, StyleDeclarationValue } from 'aphrodite';

const rotate = {
    "100%": {
        transform: "rotate(360deg)"
    }
}

const dash = {
    "0%": {
        strokeDasharray: "1, 200",
        strokeDashoffset: 0,
    },
    "50%": {
        strokeDasharray: "89, 200",
        strokeDashoffset: -35
    },
    "100%": {
        strokeDasharray: "89, 200",
        strokeDashoffset: -124,
    }
}

export default StyleSheet.create({
    block: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
    },
    circle: {
        animationName: [rotate],
        animationDuration: "2s",
        animationIterationCount: 'infinite',
        height: "100%",
        transformOrigin: "center center",
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
    },
    text: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
    },
    hiddenText: {
        opacity: 0,
        visibility: "hidden",
        whiteSpace: "nowrap",
    },
    textIcon: {
        display: "flex",
        alignItems: "center",
    },
    withIcon: {
        display: "inline-flex",
        alignItems: "center",
    },
    icon: {
        display: "flex",
        marginRight: 5,
    },
    defaultCursor: {
        cursor: "default",
    },
    success: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        width: "100%",
        height: "100%",
    },
    failure: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        width: "100%",
        height: "100%",
    },
    disabled: {
        filter: "grayscale(100%)",
        cursor: "default",
    }
}) 

export function Loader(buttonHeight:string|number|undefined):StyleDeclarationValue {
    let height = buttonHeight ? parseInt(buttonHeight.toString()) : 30;
    return StyleSheet.create({
        default: {
            position: "relative",
            margin: "0 auto",
            width: Math.round(height*0.66),
            ":before": {
                content: '""',
                display: "block",
                paddingTop: "100%",
            }
        }
    }).default
}

export function Path(color?:string):StyleDeclarationValue {
    return StyleSheet.create({
        default: {
            strokeDasharray: "1, 200",
            strokeDashoffset: 0,
            animationName: [dash],
            animationDuration: "1.5s",
            animationIterationCount: 'infinite',
            strokeLinecap: "round",
            stroke: color ? color : "white",
        }
    }).default
}