import { toast, ToastContent, ToastOptions } from 'react-toastify';

// DOCS
// https://fkhadra.github.io/react-toastify/introduction

type callback = <T = {}>(props: T) => void;

const OnOpen = (callback?:callback) => <T = {}>(props: T) => {
    if (typeof callback === "function") {
        callback(props)
    }

    // const audio = new Audio('/sounds/toast.mp3');
    // audio.play();
}

const OnClose = (callback?:callback) => <T = {}>(props: T) => {
    if (typeof callback === "function") {
        callback(props)
    }
}


export function showToast(Content:ToastContent, options?:ToastOptions):string | number {
    options = options || {};
    options.onClose = OnClose(options.onClose)
    options.onOpen = OnOpen(options.onClose)

    return toast(Content, options);
}

export function showSuccessToast(Content:ToastContent, options?:ToastOptions):string | number {
    return showToast(Content, {...options, ...{type: "success"}})
}