import React, { useState, useEffect, useRef }  from 'react';
// api
import SendRequest from 'src/api/SendRequest';
// runtime
import { ShowGrowl, HideGrowl } from 'src/view/components/growls/events';
import { showToast } from 'src/runtime/toasts';
// types
import { Request } from 'src/models/Requests';
// comoponents
import DropDown from 'src/view/components/dropdowns/DropDownOnClick.react';
// icons
import { ReactComponent as CheckIcon } from 'src/icons/check.svg';
import { ReactComponent as Down } from 'src/icons/down.svg';
import { ReactComponent as NameIcon } from './img/name.svg';
import { ReactComponent as UrlIcon } from './img/url.svg';
import { ReactComponent as CompanyIcon } from './img/company.svg';
import { ReactComponent as EmailIcon } from './img/email.svg';
import { ReactComponent as PhoneIcon } from './img/phone.svg';
// styles
import { css } from 'aphrodite';
import Input from 'src/styles/input.css';
import Styles from './form.css';

interface ErrorType {
    full_name:          boolean,
    email:              boolean,
    company:            boolean,
    company_revenue:    boolean,
}

const DefaultErrors = {
    full_name:          false,
    email:              false,
    company:            false,
    company_revenue:    false,
};

interface Props {
    source: string,
}

export default function (props: Props) {
    const {
        source,
    } = props;

    const input = useRef<HTMLInputElement>(null);

    const [ Details, SetDetails ] = useState<Request>({
        full_name:          "",
        url:                "",
        company:            "",
        company_revenue:    "0K - 100K",
        email:              "",
        phone_number:       "",
        accept_mailing:     true
    });

    const [ Err, SetErr] = useState<ErrorType>({...DefaultErrors});
    const [sent, setSent] = useState<boolean>(false);

    const handleChange = (type: keyof Request) => (e: React.ChangeEvent<HTMLInputElement>) => {
        SetErr({
            ...Err,
            [type]: false,
        })

        SetDetails({
            ...Details,
            [type]: type === "accept_mailing" ? 
                        !Details.accept_mailing : 
                        e.target.value,
        })
    }

    const changeRevenue = (value: string) => (e: React.MouseEvent) => {
        SetDetails({
            ...Details,
            company_revenue: value,
        })
    }

    const Submit = async () => {
        if (Details.full_name.length === 0) {
            SetErr({...Err, full_name: true});
        } else if (Details.email.length === 0) {
            SetErr({...Err, email: true});
        } else if (Details.company.length === 0) {
            SetErr({...Err, company: true});
        } else {
            ShowGrowl("top-line");
            try {
                await SendRequest(Details);
                setSent(true);
                showToast("Request successfully sent, we will get in touch shortly");
            } catch (error) {
                console.log(error);
            }
            HideGrowl();
        }
    };

    useEffect(() => {
        if (source === 'lightbox' && input.current) {
            input.current.focus();
        }
    }, [source])

    return (
        <div className={css(Styles.formContent)}>
            <div className={css(sent && Styles.formHide)}>
                <div className={css(Styles.row)}>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)} htmlFor={`full_name_${source}`}>Full Name<span className={css(Input.req)}>*</span></label>
                            <div className={css(Input.inputBlock)}>
                                <NameIcon className={css(Input.icon)} />
                                <input {...{
                                    id: "full_name_" + source,
                                    ref: input,
                                    onChange: handleChange('full_name'),
                                    type: "text",
                                    value: Details.full_name,
                                    className: css(Input.base, Input.withIcon, Err.full_name && Input.inputError)
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)} htmlFor={`url_${source}`}>URL</label>
                            <div className={css(Input.inputBlock)}>
                                <UrlIcon className={css(Input.icon)} />
                                <input {...{
                                    id: "url_" + source,
                                    onChange: handleChange('url'),
                                    type: "text",
                                    value: Details.url,
                                    className: css(Input.base, Input.withIcon)
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.row)}>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)} htmlFor={`company_${source}`}>Company<span className={css(Input.req)}>*</span></label>
                            <div className={css(Input.inputBlock)}>
                                <CompanyIcon className={css(Input.icon)} />
                                <input {...{
                                    id: "company_" + source,
                                    onChange: handleChange('company'),
                                    type: "text",
                                    value: Details.company,
                                    className: css(Input.base, Input.withIcon, Err.company && Input.inputError)
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)}>Company revenue<span className={css(Input.req)}>*</span></label>
                            <DropDown {...{
                                Element: () => (
                                    <div className={css(Input.base, Styles.select)}>
                                        {Details.company_revenue}
                                        <Down className={css(Styles.selectIcon)} />
                                    </div>
                                ),
                                DropElement: () => (
                                    <ul className={css(Styles.dropdown)}>
                                        <li className={css(Styles.dropdownItem)} onClick={changeRevenue('0K - 100K')}>0K - 100K</li>
                                        <li className={css(Styles.dropdownItem)} onClick={changeRevenue('100K - 250K')}>100K - 250K</li>
                                        <li className={css(Styles.dropdownItem)} onClick={changeRevenue('250K - 1M')}>250K - 1M</li>
                                        <li className={css(Styles.dropdownItem)} onClick={changeRevenue('1M - 5M')}>1M - 5M</li>
                                        <li className={css(Styles.dropdownItem)} onClick={changeRevenue('5M +')}>5M +</li>
                                    </ul>
                                ),
                                fixed: true,
                            }} />
                        </div>
                    </div>
                </div>
                <div className={css(Styles.row)}>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)} htmlFor={`email_${source}`}>Email<span className={css(Input.req)}>*</span></label>
                            <div className={css(Input.inputBlock)}>
                                <EmailIcon className={css(Input.icon)} />
                                <input {...{
                                    id: "email_" + source,
                                    onChange: handleChange('email'),
                                    type: "email",
                                    value: Details.email,
                                    className: css(Input.base, Input.withIcon, Err.email && Input.inputError)
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.col)}>
                        <div className={css(Input.block)}>
                            <label className={css(Input.label)} htmlFor={`phone_number_${source}`}>Phone number</label>
                            <div className={css(Input.inputBlock)}>
                                <PhoneIcon className={css(Input.icon)} />
                                <input {...{
                                    id: "phone_number_" + source,
                                    onChange: handleChange('phone_number'),
                                    type: "text",
                                    value: Details.phone_number,
                                    className: css(Input.base, Input.withIcon)
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(Input.block)}>
                    <label className={css(Input.checkbox)}>
                        <input {...{
                            type: "checkbox",
                            className: css(Input.checkboxInput),
                            checked: Details.accept_mailing,
                            onChange: handleChange("accept_mailing"),
                        }} />
                        <div className={css(Input.checkboxIconContainer)}>
                            {Details.accept_mailing ? (
                                <CheckIcon className={css(Input.checkboxIcon)} width="10" height="10" />
                            ) : null}
                        </div>
                        <span className={css(Styles.label)}>Yes, I’d like to be included on the 8th Byte Newsletter and receive additional information that may be of interest to  me. <a className={css(Styles.link)} href="/privacy" rel="noreferrer" target="_blank">Privacy Policy</a>.</span>
                    </label>
                </div>
                <button type="button" className={css(Styles.btn)} onClick={Submit}>
                    Continue
                </button>
            </div>
            {sent ? (
                <div className={css(Styles.sent)}>
                    <h2 className={css(Styles.sentTitle)}>Thank you.</h2>
                    <h3 className={css(Styles.subTitle)}>We’ll be in contact with you shortly.</h3>
                </div>
            ) : null}
        </div>
    )
}