import { get } from "./api";
import { IS_SSR } from "./ssr";

interface ExtraTag {
    Tag:        string
    Attributes: null
    Content:    string
}

export async function UpdateDefaultExtraTags(path:string) {
    if (IS_SSR) {
        return
    }

    const [ res, err ] = await get<ExtraTag[]>({
        url: `extratags${path && path !== "/" ? `/${path}` : ""}`
    });

    if (err == null && Array.isArray(res)) {
        res.forEach(tag => {
            switch (tag.Tag) {
                case "title":
                    document.title = tag.Content
                    break;
            
                default:
                    break;
            }
        });
    }
}