import { put, ErrorResponse } from 'src/utils/api';
import { SessionAPIRuntime } from 'src/models/Session';

export default async function(email: string, password: string):Promise<[SessionAPIRuntime, ErrorResponse<any> | null]> {
    const [ res, err ] = await put<SessionAPIRuntime>({ 
        url: "session",
        body: {
            primary_email: email,
            password,
        }
    });

    if (err != null) {
        return [ res, err ]
    }

    SessionAPIRuntime.check(res)
    
    return [ res, null ];
}