import React, { PropsWithChildren, useState, useEffect, useRef } from 'react';
import { css, StyleDeclaration } from 'aphrodite';
import Styles from './SimpleDropDown.scss';

interface Props {
    Element:        React.ComponentType | JSX.Element,
    DropElement?:   React.ComponentType | JSX.Element,
    position?:      "top" | "right" | "bottom" | "left-full",
    event?:         "click" | "hover" | "none"
    className?:     StyleDeclaration,
}

export default function SimpleDropDown({
    Element,
    DropElement,
    position,
    children,
    event,
    className,
}: PropsWithChildren<Props>) {
    const wrapper = useRef<HTMLDivElement>(null);
    const [ Show, SetShow ] = useState(false);

    function OnClick() {
        if (event === "click") {
            SetShow(!Show);
        }
    }

    useEffect(() => {
        function onClickOverlay(e: MouseEvent) {
            if (Show) {
                if (wrapper.current && e.target instanceof HTMLElement) {
                    if (!wrapper.current.contains(e.target)) {
                        SetShow(false);
                    }
                }
            }
        };

        if (event === "click") {
            window.addEventListener('click', onClickOverlay)
            return () => {
                window.removeEventListener('click', onClickOverlay)
            }
        }
    }, [ Show, event ])

    event = typeof event === "undefined" ? "hover" : event;

    return (
        <section {...{
            ref:        wrapper,
            className:  css(Styles.dropdown, event === "hover" ? Styles.eventHover : null),
            onClick:    OnClick,
        }} >
            { typeof Element === "function" ? <Element /> : Element }
            <aside className={`dd-aside ${css(
                Styles.dropdownAside, 
                Show ? Styles.show : null,
                typeof position !== 'undefined' && position === 'right' && Styles.dropdownAsideRight,
                typeof position !== 'undefined' && position === 'top' && Styles.dropdownAsideTop,
                typeof position !== 'undefined' && position === "left-full" && Styles.dropdownAsideFull,
                className ? className : "",
            )}`}>
                { children ? children : DropElement ? typeof DropElement === "function" ? <DropElement /> : DropElement : null }
            </aside>
        </section>
    );
}