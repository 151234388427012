import * as runtypes from 'runtypes';
import { Stack, Record, List } from 'immutable';
import EventEmitter from 'eventemitter3';
import config from './config';
import { session } from 'src/runtime/session';
const Events = new EventEmitter()

const StatusFactory = Record<{
    IsConnected: boolean
    IsErroring: boolean
}>({
    IsConnected: false,
    IsErroring: false,
});

let status = StatusFactory();
let stack = Stack<string>();
let listeners = List<string>();

const ResponseRuntime = runtypes.Record({
    subject:        runtypes.String,
    status_code:    runtypes.Number,
    status_text:    runtypes.String.Or(runtypes.Undefined)
})

export interface Response<P> {
    subject:        string,
    payload:        P,
    status_code:    number,
    status_text?:   string,
}

interface Message<P> {
    subject:        string,
    payload:        P,
}

let Socket:WebSocket;

export function ReConnect() {
    Socket.close()
}

function connect() {
    if (status.get("IsConnected")) {
        return;
    }

    const prot = config.env === config.envs.production ? "wss" : "ws";

    Socket = new WebSocket(`${prot}://${document.location.host}/ws`);

    Socket.onopen = function () {
        status = status.set("IsConnected", true).set("IsErroring", false);
        if (stack.size > 0) {
            while (stack.size !== 0) {
                const msg = stack.peek();
                stack = stack.pop();
                if (typeof msg !== "undefined") {
                    SendMessageString(msg);
                }
            }
        }
    };
    
    Socket.onerror = function (event) {
        status = status.set("IsErroring", true);
        console.log(event)
    }
    
    Socket.onclose = function () {
        status = status.set("IsConnected", false);
        setTimeout(() => {
            reconnect();
        }, 1000);
    }
    
    Socket.onmessage = function(event) {
        try {
            const data:Response<any> = JSON.parse(event.data); 
            
            ResponseRuntime.check(data);

            listeners.forEach(listener => {
                if (listener === data.subject) {
                    Events.emit(data.subject, data);
                }
            });
        } catch (error) {
            console.groupCollapsed("Websocket: message is not json or not valid")
            console.error(`event data: ${event.data}`)
            console.error(error)
            console.groupEnd();
        }
    }   
}

connect();

function reconnect() {
    const subs = Events.eventNames();
    if (subs.length > 0) {
        subs.forEach(sub => {
            if (typeof sub == "string") {
                SendMessageString(`SUB ${sub}`);
            }
        })
    }
    connect();
}

export function SendMessage<P>(msg:Message<P>) {
    msg.subject = `${config.websocket.prefix}${msg.subject}`;
    SendMessageString(JSON.stringify(msg))
}

function SendMessageString(msg:string) {
    if (status.IsConnected) {
        Socket.send(msg);
    } else {
        stack = stack.push(msg);
    }
}

export function BindOnSubject<P>(subject:string, func: (data:Response<P>) => void) {
    subject = `${config.websocket.prefix}${subject}`;

    listeners = listeners.push(subject);
    SendMessageString(`SUB ${subject}`);
    Events.addListener(subject, func);
}

export function UnBindOnSubject<P>(subject:string, func: (data:Response<P>) => void) {
    subject = `${config.websocket.prefix}${subject}`;

    listeners = listeners.filter(listener => listener !== subject);
    SendMessageString(`UNSUB ${subject}`);
    Events.removeListener(subject, func);
}

export function BindOnPersonal<P>(func: (data:Response<P>) => void) {
    BindOnSubject(`personal-${session.User.get("guid")}`, func);
}

export function UnBindOnPersonal<P>(func: (data:Response<P>) => void) {
    UnBindOnSubject(`personal-${session.User.get("guid")}`, func);
}