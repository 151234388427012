import React, { useEffect, useState } from 'react';
import moment from 'moment';
import GetUsers from 'src/api/GetUsers';
import PrettyTable from 'src/view/components/tables/PrettyTable.react';
import { ConsoleUser } from 'src/models/ConsoleUser';
import { showLightbox } from 'src/view/components/lightbox/lightbox.react';
import UserSettings from './users/UserSettings.react';
import { css, StyleSheet } from 'aphrodite';

export default function() {
    const [ Users, SetUsers ] = useState<ConsoleUser[]>([]);
    const [ Reload, SetReload ] = useState(false);

    function OnClickAdd(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        showLightbox({
            selector:   "add-user",
            Component:  UserSettings,
            content:    {
                OnAdd: () => {
                    SetReload(!Reload);
                }
            },
        });
    }

    const EditUser = (user:ConsoleUser) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        showLightbox({
            selector:   "add-user",
            Component:  UserSettings,
            content:    {
                prefill: user,
                OnAdd: () => {
                    SetReload(!Reload);
                }
            },
        });
    }

    useEffect(() => {
        async function load() {
            try {
                const res = await GetUsers();
                SetUsers(res); 
            } catch (error) {
                alert(error);
            }
        }

        load();
    }, [ Reload ]);

    return (
        <div className={ css(styles.page) }>
            <PrettyTable {...{
                title: () => {
                    return (
                        <div className={ css(styles.header) }>
                            <h1 className={ css(styles.title) }>Users list</h1>
                            <a href="/" target="_blank" onClick={ OnClickAdd }>add user</a>
                        </div>
                    );
                },
                thead: [
                    "id",
                    "email",
                    "created_on",
                    "client of",
                    "can view console",
                    "can view users",
                    "can view projects",
                    "can run deploy",
                ],
                tbody: Users ? Users.map(user => {
                    return [
                        user.id,
                        (
                            <span>
                                { user.primary_email }
                                {" "}
                                <a href="/" onClick={ EditUser(user) }>edit</a>
                            </span>
                        ),
                        moment.unix(user.created_on).format("MMMM Do, h:mm a"),
                        user.is_client && user.client_project ? (
                            <strong>
                                    <a {...{
                                        href: `/console/projects/${user.client_project.id}`,
                                        target: "_blank",
                                    }}>
                                        {user.client_project.title}
                                    </a>
                            </strong>
                        ) : "",
                        user.can_view_console ? () => <strong>yes</strong> : "no",
                        user.can_manage_users ? () => <strong>yes</strong> : "no",
                        user.can_manage_projects ? () => <strong>yes</strong> : "no",
                        user.can_run_deploy ? () => <strong>yes</strong> : "no",
                    ]
                }) : [],
                withBorder: false,
            }} />
        </div>
    );
}

const styles = StyleSheet.create({
    page: {
        margin: 20,
    },
    header: {
        display: "flex",
        height: 40,
        lineHeight: "40px",
    },
    title: {
        margin: "0 20px 0 0",
        fontSize: "20px",
        lineHeight: "40px",
    },
})