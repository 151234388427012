import { StyleSheet } from 'aphrodite';
import { black, yellow } from 'src/styles/colors';

export default StyleSheet.create({
    footer: {
        display: "flex",
        width: "100%",
        padding: "20px 80px",
        color: "#fff",
        backgroundColor: black,
        alignItems: "center",
        justifyContent: "space-between",
    },
    copyright: {
        padding: "0 0 0 20px",
        "@media(max-width: 995px)": {
            width: "100%",
            textAlign: "center",
        },
    },
    menu: {
        display: "flex",
        alignItems: "center",
        "@media(max-width: 995px)": {
            display: "none",
        },
    },
    menuItem: {
        marginRight: 20,
        listStyle: "none",
    },
    menuLink: {
        fontSize: 17,
        fontWeight: 600,
        color: "#fff",
        textDecoration: "none",
        cursor: "pointer",
    },
    privacyLink: {
        fontSize: 17,
        fontWeight: 400,
        color: yellow,
        textDecoration: "none",
        whiteSpace: "nowrap",
        cursor: "pointer",
    }
})