import React from 'react';
import { history } from 'src/utils/history';
// styles
import { css } from 'aphrodite';
import Styles from './footer.css';

export default function () {
    const open = (url: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(url);
    }

    const scrollToElm = (elm: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        const sectionElm: HTMLElement | null = document.querySelector(elm);

        if (sectionElm) {
            const rect: ClientRect = sectionElm.getBoundingClientRect();
            const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            window.scroll({
                top: rect.top + scrollTop,
                behavior: 'smooth'
            });
        }
    }

    return (
        <footer className={css(Styles.footer)}>
            <ul className={css(Styles.menu)}>
                <li className={css(Styles.menuItem)}>
                    <span className={css(Styles.menuLink)} onClick={scrollToElm('#services')}>Services</span>
                </li>
                <li className={css(Styles.menuItem)}>
                    <span className={css(Styles.menuLink)} onClick={scrollToElm('#tech')}>Technology</span>
                </li>
                <li className={css(Styles.menuItem)}>
                    <span className={css(Styles.menuLink)} onClick={scrollToElm('#portfolio')}>Portfolio</span>
                </li>
            </ul>
            <div className={css(Styles.copyright)}>
                Copyright {new Date().getFullYear()} 8th Byte LLC. All rights reserved. ~{' '}
                <a className={css(Styles.privacyLink)} href="/privacy" onClick={open('/privacy')}>Privacy policy</a>
            </div>
        </footer>
    );
}