import { post, ErrorResponse } from 'src/utils/api';

export default async function(email: string):Promise<ErrorResponse<any> | null> {
    const [ , err ] = await post({ 
        url: "session/passwords",
        body: {
            email,
        }
    });

    return err
}