import * as runtypes from 'runtypes';
import { Record as IRecord } from 'immutable';

export const SessionRuntimeType = runtypes.Record({
});

export interface SessionRuntimeType {
}


interface SessionRuntimeRecord {
}

export const SessionRuntime = IRecord<SessionRuntimeRecord>({
});

export type SessionRuntime = IRecord<SessionRuntimeRecord>;

export function MakeFromRuntime(data:SessionRuntimeType):IRecord<SessionRuntimeRecord> {
    return SessionRuntime({...data, ...{
    }});
}

