import React, { useState } from 'react';
import { showLightbox } from 'src/view/components/lightbox/lightbox.react';
// components
import Header from 'src/view/components/header/header.react';
import Footer from 'src/view/components/footer/footer.react';
import Form from 'src/view/components/form/formOnPage.react';
import FormLightbox from 'src/view/components/form/formLightbox.react';
// img
import Flap from './img/flap.png';
import Cherry from './img/cherry.png';
import Zeta from './img/zeta.png';
import Pathways from './img/pathways.png';
// icons
import { ReactComponent as Hero } from './img/hero.svg';
import { ReactComponent as Cloud } from './img/cloud.svg';
import { ReactComponent as Architecture } from './img/architecture.svg';
import { ReactComponent as Mobile } from './img/mobile.svg';
import { ReactComponent as Security } from './img/security.svg';
import { ReactComponent as Tech } from './img/tech.svg';
import { ReactComponent as Desktop } from 'src/icons/desktop.svg';
import { ReactComponent as CloudIcon } from 'src/icons/cloud.svg';
// styles
import { css } from 'aphrodite';
import Grid from 'src/styles/grid.css';
import Styles from './landing.css';

export default function () {
    const open = (url: string) => (e: React.MouseEvent) => {
        if (e) e.preventDefault();
        window.open(url);
    }

    const contact = () => {
        showLightbox({
            selector: "contact-lightbox",
            Component: FormLightbox,
            content: {},
        })
    }

    return (
        <article className={css(Styles.page)}>
            <Header />
            <div className={css(Styles.main)}>
                <div className={css(Styles.hero)}>
                    <div className={css(Grid.container, Styles.heroContainer)}>
                        <div className={css(Grid.row)}>
                            <div className={css(Grid.col6)}>
                                <h1 className={css(Styles.heroTitle)}>
                                    Software Development Team <span className={css(Styles.heroTitleLine)}>Or Team Extension</span>
                                </h1>
                                <p className={css(Styles.heroText)}>
                                    8th Byte’s dedicated development team in Eastern Europe and the US is your key to developing awesome software products. We have top notch software developers & project managers ready to bring their talent and experience to your team.
                                </p>
                                <button type="button" className={css(Styles.btn)} onClick={contact}>
                                    Get Started
                                </button>
                            </div>
                            <div className={css(Grid.col6, Styles.heroImgBlock)}>
                                <Hero className={css(Styles.heroImg)} />
                            </div>
                        </div>
                        <div className={css(Styles.star, Styles.heroStar1)} />
                        <div className={css(Styles.star, Styles.heroStar2)} />
                        <div className={css(Styles.star, Styles.heroStar3)} />
                        <div className={css(Styles.star, Styles.heroStar4)} />
                    </div>
                </div>
                <div id="services" className={css(Styles.services)}>
                    <div className={css(Grid.container)}>
                        <h2 className={css(Styles.blockTitle, Styles.servicesTitle)}>Our Services</h2>
                        <div className={css(Grid.row, Grid.rowReverse, Styles.servicesRow)}>
                            <div className={css(Grid.col5, Grid.offset1)}>
                                <h3 className={css(Styles.title)}>Software architecture</h3>
                                <p className={css(Styles.text)}>We can design a product architecture from scratch or extend your current systems. Our highly modular and loosely-coupled code manages code complexity and allows for quick reusability.</p>
                                <button type="button" className={css(Styles.btn, Styles.plainBtn)} onClick={contact}>
                                    Contact Us
                                </button>
                            </div>
                            <div className={css(Grid.col6)}>
                                <Architecture className={css(Styles.img)} />
                            </div>
                            <div className={css(Styles.star, Styles.serviceStar1)} />
                        </div>
                        <div className={css(Grid.row, Styles.servicesRow)}>
                            <div className={css(Grid.col5)}>
                                <h3 className={css(Styles.title)}>Mobile & Web</h3>
                                <p className={css(Styles.text)}>We are pros at creating custom end-to-end enterprise and consumer mobile and web platforms: vision, architecture, design, quality assurance, deployment, maintenance.</p>
                                <button type="button" className={css(Styles.btn, Styles.plainBtn)} onClick={contact}>
                                    Contact Us
                                </button>
                            </div>
                            <div className={css(Grid.col6, Grid.offset1)}>
                                <Mobile className={css(Styles.img)} />
                            </div>
                            <div className={css(Styles.star, Styles.serviceStar2)} />
                        </div>
                        <div className={css(Grid.row, Grid.rowReverse, Styles.servicesRow)}>
                            <div className={css(Grid.col5, Grid.offset1)}>
                                <h3 className={css(Styles.title)}>Cloud solutions</h3>
                                <p className={css(Styles.text)}>Cloud infrastructure setup, migration, cloud application development and cost optimization.</p>
                                <button type="button" className={css(Styles.btn, Styles.plainBtn)} onClick={contact}>
                                    Contact Us
                                </button>
                            </div>
                            <div className={css(Grid.col6)}>
                                <Cloud className={css(Styles.img)} />
                            </div>
                            <div className={css(Styles.star, Styles.serviceStar3)} />
                            <div className={css(Styles.star, Styles.serviceStar4)} />
                        </div>
                        <div className={css(Grid.row, Styles.servicesRow)}>
                            <div className={css(Grid.col5)}>
                                <h3 className={css(Styles.title)}>Security & HIPAA</h3>
                                <p className={css(Styles.text)}>We have experience in developing secure platforms and delivering HIPAA compliant products.</p>
                                <button type="button" className={css(Styles.btn, Styles.plainBtn)} onClick={contact}>
                                    Contact Us
                                </button>
                            </div>
                            <div className={css(Grid.col6, Grid.offset1)}>
                                <Security className={css(Styles.img)} />
                            </div>
                            <div className={css(Styles.star, Styles.serviceStar5)} />
                        </div>
                    </div>
                </div>
                <div id="tech" className={css(Styles.tech)}>
                    <div className={css(Grid.container)}>
                        <div className={css(Grid.row, Grid.rowReverse)}>
                            <div className={css(Grid.col6)}>
                                <Tech className={css(Styles.img)} />
                            </div>
                            <div className={css(Grid.col6)}>
                                <TechBlock />
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.star, Styles.techStar1)} />
                    <div className={css(Styles.star, Styles.techStar2)} />
                    <div className={css(Styles.star, Styles.techStar3)} />
                </div>
                <div id="portfolio" className={css(Styles.works)}>
                    <div className={css(Grid.container)}>
                        <div className={css(Grid.row)}>
                            <div className={css(Grid.col12)}>
                                <h3 className={css(Styles.blockTitle, Styles.workTitle)}>Featured projects</h3>
                                {/* <p className={css(Styles.text, Styles.worksText)}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p> */}
                            </div>
                            <div className={css(Grid.col6)}>
                                <div className={css(Styles.workItem)} onClick={open('https://flap.gg')}>
                                    <div className={css(Styles.workItemTop)}>
                                        <a className={css(Styles.workItemTitle)} href="https://flap.gg" target="_blank" rel="noreferrer">
                                            Flap.gg
                                        </a>
                                    </div>
                                    <p className={css(Styles.text, Styles.worksText)}>An online marketplace for eSports and gaming coaches.</p>
                                    <img className={`${css(Styles.workItemBg)} works-bg`} src={Flap} alt="" />
                                </div>
                                <div className={css(Styles.workItem, Styles.workItemLast)} onClick={open('https://zetacalls.com')}>
                                    <div className={css(Styles.workItemTop)}>
                                        <a className={css(Styles.workItemTitle)} href="https://zetacalls.com" target="_blank" rel="noreferrer">
                                            Zeta Calls
                                        </a>
                                    </div>
                                    <p className={css(Styles.text, Styles.worksText)}>Free, secure & private video calls and conferencing.</p>
                                    <img className={`${css(Styles.workItemBg)} works-bg`} src={Zeta} alt="" />
                                </div>
                            </div>
                            <div className={css(Grid.col6)}>
                                <div className={css(Styles.workItem)} onClick={open('http://downloadcherry.com')}>
                                    <div className={css(Styles.workItemTop)}>
                                        <a className={css(Styles.workItemTitle)} href="http://downloadcherry.com" target="_blank" rel="noreferrer">
                                            Cherry
                                        </a>
                                    </div>
                                    <p className={css(Styles.text, Styles.worksText)}>An Uber for manicure services.</p>
                                    <img className={`${css(Styles.workItemBg)} works-bg`} src={Cherry} alt="" />
                                </div>
                                <div className={css(Styles.workItem, Styles.workItemLast)} onClick={open('https://pathways.cellularagricultureaustralia.org')}>
                                    <div className={css(Styles.workItemTop)}>
                                        <a className={css(Styles.workItemTitle)} href="https://pathways.cellularagricultureaustralia.org" target="_blank" rel="noreferrer">
                                            Pathways
                                        </a>
                                    </div>
                                    <p className={css(Styles.text, Styles.worksText)}>Discover your next step into cellular agriculture!</p>
                                    <img className={`${css(Styles.workItemBg)} works-bg`} src={Pathways} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form />
            </div>
            <Footer />
        </article>
    );
}

const TechBlock = () => {
    const [active, setActive] = useState<string | null>('desktop');

    return (
        <>
            <h3 className={css(Styles.blockTitle)}>Cutting edge technology</h3>
            <div className={css(Styles.techItem, active !== 'desktop' && Styles.techItemClosed)} onClick={() => setActive(active !== 'desktop' ? 'desktop' : null)}>
                <Desktop className={css(Styles.techItemIcon)} width="24" height="24" />
                <div className={css(Styles.techItemContent)}>
                    <h4 className={css(Styles.techItemTitle)}>Platforms</h4>
                    <p className={css(Styles.text, Styles.techItemText, active !== 'desktop' && Styles.techItemTextClosed)}>When we create something from scratch we like using Go, TypeScript, React, JavaScript, Swift, Kotlin, and sometimes Flutter. We are also well versed in many other systems such as C/C++, Java, Swift, Objective-C, Node.JS, PHP/Wordpress, Ruby, Python. When working with data science and AI tasks we like using PyTorch, Pandas, R.</p>
                </div>
            </div>
            <div className={css(Styles.techItem, active !== 'cloud' && Styles.techItemClosed)} onClick={() => setActive(active !== 'cloud' ? 'cloud' : null)}>
                <CloudIcon className={css(Styles.techItemIcon)} width="24" height="24" />
                <div className={css(Styles.techItemContent)}>
                    <h4 className={css(Styles.techItemTitle)}>Cloud and DevOps</h4>
                    <p className={css(Styles.text, Styles.techItemText, active !== 'cloud' && Styles.techItemTextClosed)}>AWS, Azure, Google, Docker, Jenkins.</p>
                </div>
            </div>
        </>
    )
}
