import React  from 'react';
// comoponents
import Form from './form.react';
// styles
import { css } from 'aphrodite';
import Grid from 'src/styles/grid.css';
import Styles from './form.css';

export default function () {
    return (
        <div id="contact-form" className={css(Styles.form)}>
            <div className={css(Grid.container)}>
                <div className={css(Grid.row)}>
                    <div className={css(Grid.col5, Styles.formTitleBlock)}>
                        <h2 className={css(Styles.formTitle)}>
                            Contact Us<br />
                            And We Will Try<br />
                            To Help You
                        </h2>
                    </div>
                    <div className={css(Grid.col7)}>
                        <Form source="page" />
                    </div>
                </div>
            </div>
        </div>
    )
}