// @ts-ignore
import config from 'client-config';

interface Config {
    server: {
        host: string | null
    },
    env: number,
    envs: {
        local: number,
        production: number,
    },
    google: {
        client_id: string
    },
    public_image_path: string,
    websocket: {
        prefix: string,
    },
    stripe: {
        public_key: string,
    },
}

const cfg:Config = config;

export default cfg;