import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { css, StyleSheet } from 'aphrodite';
import { Product, DeployStatusResult } from 'src/models/Projects';
import { Lightbox, Header, Body, Content, Footer } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import GetProductDeployStatus from 'src/api/GetProductDeployStatus';
import StartProductDeploy from 'src/api/StartProductDeploy';

export default function DeployProduct({selector, close, content}: ComponentProps<{
    project_name: string,
    product: Product,
    on_start: () => void,
}>) {
    const [ Loading, SetLoading ] = useState(true);
    const [ Status, SetStatus ] = useState<DeployStatusResult>({
        status:         "deployed",
        deployed_at:    0,
        branch:         "master",
    });
    const [ Branch, SetBranch ] = useState(content.product.can_choose_branch ? "" : "master");
    const [ Reload, SetReload ] = useState(false);

    async function StartDeploy() {
        SetLoading(true);
        try {
            await StartProductDeploy(content.product.id, Branch);
            content.on_start();
        } catch (error) {
            alert(error)
        }
        SetReload(!Reload);
    }

    useEffect(() => {
        async function load() {
            SetLoading(true);
            try {
                const res = await GetProductDeployStatus(content.product.id);
                SetStatus(res);
            } catch (error) {
                alert(error);
            }

            SetLoading(false);
        };

        load();
    }, [ Reload, content.product.id ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (Status.status !== "deployed" && Status.status !== "failed") {
                SetReload(!Reload);
            }
        }, 5*1000);
        
        return () => clearTimeout(timer);
    });

    return (
        <Lightbox {...{ close }}>
            <Header {...{ selector, close }}>
                { content.project_name } - { content.product.name } deploy
            </Header>
            <Body>
                <Content styles={ styles.deploy }>
                    <div>
                        Current status: 
                        <span>
                            <strong>{" "}{ Loading ? "loading data" : Status.status }</strong>
                            { !Loading && (
                                <span className={ css(styles.status) }>
                                    { Status.deployed_at > 0 && (Status.status === "deployed" || Status.status === "failed") ? 
                                        ` at ${moment.unix(Status.deployed_at).format("h:mm a, MMMM Do")}`
                                        : null
                                    }
                                    { Status.branch.length > 0 &&(
                                        <span>
                                            {", "}
                                            <strong>{ Status.branch }</strong>
                                            {" "}branch
                                        </span>
                                    ) }
                                </span>
                            ) }
                        </span>
                        <br/>
                        { !Loading && (Status.status === "deployed" || Status.status === "failed") ? (
                            <Fragment>
                                { !content.product.can_choose_branch && <i className={ css(styles.note) }>
                                    can't choose branch, only master
                                </i> }
                                <div className={ css(styles.start_deploy) }>
                                    <input {...{
                                        placeholder:    "github branch",
                                        value:          Branch,
                                        disabled:       !content.product.can_choose_branch,
                                        className:      css(styles.input, styles.start_deploy_branch),
                                        onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                                            SetBranch(e.target.value);
                                        }
                                    }} />
                                    <br/>
                                    <button {...{
                                        type: "button",
                                        className: css(SimpleButton.button({
                                            color: SimpleButton.colors.blue,
                                            uppercase: true,
                                            fontSize: "0.75rem",
                                            fontWeight: "bold",
                                            height: 30,
                                            borderRadius: 3,
                                        })),
                                        onClick: StartDeploy,
                                    }}>
                                        Deploy
                                    </button>
                                </div>
                            </Fragment>
                        ) : Status.status !== "deployed" && Status.status !== "failed" ? (
                            <button {...{
                                type: "button",
                                className: css(SimpleButton.button({
                                    color: SimpleButton.colors.blue,
                                    uppercase: true,
                                    fontSize: "0.75rem",
                                    fontWeight: "bold",
                                    height: 30,
                                    borderRadius: 3,
                                })),
                                onClick: () => {
                                    SetReload(!Reload);
                                },
                            }}>
                                check status
                            </button>
                        ) : null }
                    </div>
                </Content>
                <Footer styles={ styles.footer }>
                    <button {...{
                        type: "button",
                        className: css(SimpleButton.button({
                            color: SimpleButton.colors.default,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                        })),
                        onClick: close,
                    }}>
                        Close
                    </button>
                </Footer>
            </Body>
        </Lightbox>
    );
}


const styles = StyleSheet.create({
    deploy: {
        padding: 20,
    },
    start_deploy: {
        display: "flex",
        alignItems: "center",
    },
    start_deploy_branch: {
        marginRight: 10,
    },
    input: {
        margin: "10px 0",
        padding: 5,
    },
    note: {
        fontSize: 12,
    },
    status: {
        fontSize: 12,
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
    }
})