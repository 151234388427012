import { StyleSheet } from 'aphrodite';
import { black, gray, yellow, orange } from 'src/styles/colors';
import Bg from './img/bg.png';

export default StyleSheet.create({
    btn: {
        position: "relative",
        width: 140,
        padding: "18px 32px",
        fontSize: 17,
        fontWeight: 600,
        color: black,
        textDecoration: "none",
        borderRadius: 8,
        backgroundColor: yellow,
        border: 0,
        outline: 0,
        textAlign: "center",
        boxShadow: "none",
        cursor: "pointer",
        overflow: "hidden",
        transition: "background-color 0.2s",
        ":focus": {
            border: 0,
            outline: 0,
        },
        ":hover": {
            backgroundColor: orange,
        },
        "@media(max-width: 768px)": {
            margin: "0 auto",
        },
        "@media(max-width: 500px)": {
            width: "100%",
        },
    },
    form: {
        position: "relative",
        padding: "100px 0",
        background: `${gray} url(${Bg})`,
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
    },
    row: {
        display: "flex",
        width: "calc(100% + 40px)",
        margin: "0 -20px",
        flexWrap: "wrap",
        "@media(max-width: 768px)": {
            display: "block",
            width: "100%",
            margin: 0,
        },
    },
    col: {
        width: "calc(50% - 40px)",
        margin: "0 20px",
        "@media(max-width: 768px)": {
            width: "100%",
            margin: 0,
        },
    },
    formTitleBlock: {
        "@media(max-width: 767px)": {
            display: "none",
        }
    },
    formTitle: {
        margin: 0,
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: 38,
        fontWeight: 700,
        lineHeight: "46px",
        color: black,
    },
    formContent: {
        padding: "70px 40px",
        backgroundColor: "#fff",
        boxShadow: "10px 30px 50px rgba(60, 88, 168, 0.05)",
        borderRadius: 8,
    },
    sentTitle: {
        margin: 0,
        fontSize: 38,
        fontWeight: 700,
        lineHeight: "46px",
        color: black,
    },
    subTitle: {
        margin: "0 0 50px 0",
        fontSize: 28,
        fontWeight: 700,
        lineHeight: "36px",
        color: black,
    },
    link: {
        color: black,
        textDecoration: "underline",
    },
    label: {
        color: black,
    },
    dropdown: {
        padding: "10px 0",
    },
    dropdownItem: {
        display: "block",
        width: "100%",
        padding: "0 10px",
        fontSize: "1rem",
        lineHeight: "2rem",
        color: "#000",
        whiteSpace: "nowrap",
        listStyle: "none",
        cursor: "pointer",
    },
    select: {
        position: "relative",
        lineHeight: "49px",
    },
    selectIcon: {
        position: "absolute",
        top: "50%",
        right: 10,
        marginTop: -3,
        fill: black,
    },
    formHide: {
        visibility: "hidden",
    },
    sent: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
    }
});