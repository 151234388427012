import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { css, StyleSheet } from 'aphrodite';
import { Project, DeployStatusResult } from 'src/models/Projects';
import GetProjects from 'src/api/GetProjects';
import { showLightbox, showWarning } from 'src/view/components/lightbox/lightbox.react';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import PrettyTable from 'src/view/components/tables/PrettyTable.react';
import GetProductDeployStatus from 'src/api/GetProductDeployStatus';
import { HideGrowl, ShowGrowl } from '../components/growls/events';
import ToggleProductServer from 'src/api/ToggleProductServer';
import DeployProduct from './projects/DeployProduct.react';
import UsersList from './projects/UsersList.react';
import useMaxWidth from 'src/packages/hooks/useMaxWidth';

export default function() {
    const [ Projects, SetProjects ] = useState<Project[]>([]);
    const [ Reload, SetReload ] = useState(false);
    const [ LoadedDeploys, SetLoadedDeploys ] = useState<Record<number, DeployStatusResult>>({});
    const IsLess1000Width = useMaxWidth(1000);
    const IsLess700Width = useMaxWidth(700);

    const ShowDeploy = (id:number) => async (e?:React.MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        try {
            ShowGrowl("top-line");
            const res = await GetProductDeployStatus(id);
            SetLoadedDeploys({...LoadedDeploys, [id]: res});
        } catch (error) {
            alert(error);
        }

        HideGrowl();
    }

    useEffect(() => {
        async function load() {
            try {
                const res = await GetProjects();
                SetProjects(res); 
            } catch (error) {
                alert(error);
            }
        }

        load();
    }, [ Reload ]);

    return (
        <div className={ css(styles.page) }>
            <ul className={ css(styles.list) }>
                { Projects.map(project => (
                    <li key={ project.id } className={ css(styles.project) }>
                        <div className={ css(styles.title) }>
                            <h2 className={ css(styles.h2) }>
                                { project.name }{" - "}
                                <span className={ css(project.is_active && styles.active) }>
                                    { project.is_active ? "active" : "inactive" }
                                </span>
                            </h2>
                            <div>
                                contact:{" "}
                                { project.contact.name }, { project.contact.email }
                                { project.contact.phone.length > 0 && (
                                    <span>
                                        {", "}
                                        phone - { project.contact.phone }
                                    </span>
                                ) }
                            </div>
                        </div>
                        <PrettyTable {...{
                            thead: [
                                "product name",
                                !IsLess700Width ? "kind" : null,
                                !IsLess700Width ? "status" : null,
                                !IsLess700Width ? "team" : null,
                                !IsLess1000Width ? "server status" : null,
                                !IsLess1000Width ? "last deploy" : null,
                                "actions",
                            ].filter(Boolean),
                            tbody: project.products.map(product => {
                                return [
                                    () => (
                                        <span>
                                            {product.name}
                                            {" "}
                                            { product.url.length > 0 && (
                                                <span className={ css(styles.name_link) }>
                                                    (
                                                    <a {...{
                                                        href: product.url,
                                                        target: "_blank",
                                                    }}>
                                                        { product.url }    
                                                    </a>
                                                    )
                                                </span>
                                            )}
                                        </span>
                                    ),
                                    !IsLess700Width ? product.kind : null,
                                    !IsLess700Width ? () => (
                                        <span className={ css(product.is_active && styles.active) }>
                                            { product.is_active ? "active" : "inactive" }
                                        </span>
                                    ) : null,
                                    !IsLess700Width ? () => (
                                        <span>
                                            { product.users.length } members
                                            {", "}
                                            <a href="/" onClick={(e:React.MouseEvent) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                showLightbox({
                                                    Component:  UsersList,
                                                    selector:   "users-list",
                                                    content:    {
                                                        project_name: project.name,
                                                        product,
                                                    }
                                                })
                                            }}>
                                                view
                                            </a>
                                        </span>
                                    ) : null,
                                    !IsLess1000Width ? () => (
                                        <span>
                                            { product.can_deploy ? product.can_stop && product.is_stopped ? (
                                                <span className={ css(styles.stopped) }>stopped</span>
                                            ) : (
                                                <span className={ css(styles.active) }>running</span>
                                            ) : null  }
                                        </span>
                                    ) : null,
                                    !IsLess1000Width ? () => {
                                        if (!product.can_deploy) {
                                            return null;
                                        } else if (LoadedDeploys[product.id]) {
                                            return (
                                                <span>
                                                    <span>
                                                        { LoadedDeploys[product.id].status }
                                                        { " "}
                                                    </span>
                                                    <strong>{ LoadedDeploys[product.id].branch }</strong>
                                                    { LoadedDeploys[product.id].deployed_at > 0 && (
                                                        <span>
                                                            {" "}
                                                            at {moment.unix(LoadedDeploys[product.id].deployed_at).format("h:mm a, MMMM Do") }
                                                        </span>
                                                    ) }
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <a href="/" onClick={ ShowDeploy(product.id) }>show</a>  
                                            );
                                        }
                                    } : null,
                                    () => (
                                        <span>
                                            { product.can_deploy && !product.is_stopped && (
                                                <button {...{
                                                    type: "button",
                                                    className: css(SimpleButton.button({
                                                        color: SimpleButton.colors.blue,
                                                        uppercase: true,
                                                        fontSize: "0.75rem",
                                                        fontWeight: "bold",
                                                        height: 30,
                                                        borderRadius: 3,
                                                    }), styles.deploy_button),
                                                    onClick: () => {
                                                        showLightbox({
                                                            Component:  DeployProduct,
                                                            selector:   "deploy-product",
                                                            content:    {
                                                                project_name: project.name,
                                                                product,
                                                                on_start: () => {
                                                                    SetLoadedDeploys([]);
                                                                }
                                                            }
                                                        })
                                                    },
                                                }}>
                                                    Deploy
                                                </button>
                                            ) }
                                            { product.can_stop && (
                                                <button {...{
                                                    type: "button",
                                                    className: css(SimpleButton.button({
                                                        color: product.is_stopped ? SimpleButton.colors.green : SimpleButton.colors.red,
                                                        uppercase: true,
                                                        fontSize: "0.75rem",
                                                        fontWeight: "bold",
                                                        height: 30,
                                                        borderRadius: 3,
                                                    })),
                                                    onClick: () => {
                                                        showWarning(
                                                            () => (
                                                                <div className={ css(styles.warn_content) }>
                                                                    <span>Are you sure you want to </span>
                                                                    {" "}
                                                                    <strong>{product.is_stopped ? "start" : "stop"}</strong>
                                                                    {" "}
                                                                    <span>{project.name} {product.name} server</span>
                                                                </div>
                                                            ),
                                                            {
                                                                title: "Server status",
                                                                buttonClick: async () => {
                                                                    try {
                                                                        await ToggleProductServer(product.id, !product.is_stopped);
                                                                    } catch (error) {
                                                                        console.log(error);
                                                                        alert(error);
                                                                    }
                                                                    SetReload(!Reload);
                                                                },
                                                                button: "confirm",
                                                            }
                                                        )
                                                    },
                                                }}>
                                                    { product.is_stopped ? "Start server" : "Stop server" }
                                                </button>
                                            ) }
                                        </span>
                                    ),
                                ].filter(Boolean)
                            }),
                            withBorder: false,
                            columnsSizes: [
                                150,
                                !IsLess700Width ? 40 : null,
                                !IsLess700Width ? 70 : null,
                                !IsLess700Width ? 70 : null,
                                !IsLess1000Width ? 70 : null,
                                !IsLess1000Width ? 100 : null,
                                100,
                            ].filter(Boolean)
                        }} />
                    </li>
                )) }
            </ul>
        </div>
    );
}


const styles = StyleSheet.create({
    page: {
        margin: 20,
        fontSize: 13,
    },
    list: {
        listStyle: "none",
    },
    title: {
        marginBottom: 10,
        display: "flex",
        alignItems: "center",
    },
    h2: {
        flexGrow: 1,
        fontSize: "18px",
        lineHeight: "18px",
    },
    name_link: {
        fontSize: 11,
    },
    active: {
        color: "green",
        fontWeight: "bold",
    },
    stopped: {
        color: "darkred",
        fontWeight: "bold",
    },
    project: {
        background: "rgba(0,0,0,0.1)",
        padding: 10,
        margin: "10px 0",
        borderRadius: 5,
    },
    deploy_button: {
        marginRight: 10,
    },
    warn_content: {
        padding: 20,
    }
})