import { get } from 'src/utils/api';
import { DeployStatusResult } from 'src/models/Projects';

export default async function (id: number): Promise<DeployStatusResult> {
    const [res, err] = await get<DeployStatusResult>({
        url: `/console/products/${id}/deploys/status`,
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    DeployStatusResult.check(res)

    return res;
}