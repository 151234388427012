import { post } from 'src/utils/api';

export default async function (
    email: string, 
    password: string,
    is_client: boolean,
    project_id: number,
    can_view_console: boolean,
    can_manage_users: boolean,
    can_manage_projects: boolean,
    can_run_deploy: boolean,
):Promise<string | null> {
    const [ , err ] = await post({
        url: "console/users",
        body: {
            email,
            password,
            cms: {
                is_client,
                project_id,
                can_view_console,
                can_manage_users,
                can_manage_projects,
                can_run_deploy,
            }
        }
    });

    return err ? err.status_text : null;
}