import React from 'react';
import { ReactComponent as Logo } from 'src/icons/logo.svg';
// styles
import { css } from 'aphrodite';
import Styles from './login.css';

export default function() {
    return (
        <Logo className={css(Styles.logo)} />
    );
}