import { StyleSheet } from 'aphrodite';

export const GetZIndex = (order:number) => {
    return StyleSheet.create({
        base: {
            zIndex: 1298 + order,
        }
    }).base;
};

export const Styles = StyleSheet.create({
    overlay: {
        position:       "fixed",
        background:     "rgba(0, 0, 0, 0.5)",
        left:           0,
        right:          0,
        top:            0,
        bottom:         0,
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
        overflow:       "auto",
        opacity:        0,
        transition:     "opacity 0.2s",
    },
    showOverlay: {
        opacity: 1,
    },
    wrapper: {
        width:              "660px",
        maxHeight:          "100vh",
        maxWidth:           "100%",
        display:            "flex",
        padding:            "30px",
        flexDirection:      "column",
        transform:          "translateY(-20px)",
        transition:         "transform 0.2s",
    },
    showWrapper: {
        transform:          "translateY(0px)",
    },
    _lightbox: {
        borderRadius:       6,
        backgroundColor:    "white",
        display:            "flex",
        flexDirection:      "column",
        width:              "100%",
        maxWidth:           "600px",
    },
    _body: {
        flexGrow:       1,
        display:        "flex",
        flexDirection:  "column",
    },
    _header: {
        position:       "relative",
        fontSize:       16,
        fontWeight:     "bold",
        textAlign:      "left",
        margin:         0,
        padding:        "15px 50px 15px 15px",
        lineHeight:     "1.4",
        background:     "#f7f7f7",
        textShadow:    "1px 1px 0px #ffffff",
        borderRadius:   "6px 6px 0 0",
    },
    _headerWithClose: {

    },
    _headerClose: {
        display:            "flex",
        alignItems:         "center",
        justifyContent:     "center",
        position:           "absolute",
        top:                "50%",
        right:              15,
        width:              30,
        height:             30,
        marginTop:          -15,
        borderRadius:       3,
        cursor:             "pointer",
        ":hover": {
            backgroundColor: "#dfdfdf",
        }
    },
    _content: {

    },
    _footer: {

    }
});