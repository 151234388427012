import { post } from 'src/utils/api';
import { Request } from 'src/models/Requests';

// TODO real user_id
export default async function (details: Request) {
    const [res, err] = await post({
        url: "requests",
        body: details,
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    return res;
}