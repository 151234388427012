import React from 'react';
// models
import { PropsType } from './model';
// components
import { Content, Footer } from 'src/view/components/lightbox/lightbox.react';
// styles
import { css } from 'aphrodite';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import Styles from './login.css';


export default function({SetPage}:PropsType) {
    function onClickContinue() {
        SetPage("sign-in")
    }

    return (
        <section className={ css(Styles.form) }>
            <Content styles={ Styles.inputs }>
                Successfully changed account password
            </Content>
            <Footer styles={Styles.bottomCenter}>
                <button {...{
                    type: "submit",
                    className: css(SimpleButton.button({
                        color:          SimpleButton.colors.blue,
                        uppercase:      true,
                        fontSize:       "0.75rem",
                        fontWeight:     "bold",
                        height:         45,
                        borderRadius:   3,
                    })),
                    onClick: onClickContinue,
                }}>
                    Sign in
                </button>
            </Footer>
        </section>
    )
}