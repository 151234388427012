import React, { useState } from 'react';
import Header from 'src/view/components/header/header.react';
import Footer from 'src/view/components/footer/footer.react';
import { Lightbox, Header as LBHeader, Body, Content, Footer as LBFooter, showLightbox } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
import {
    RouteComponentProps,
} from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { session, SignOut, UpdatePassword } from 'src/runtime/session';
import { history } from 'src/utils/history';
import UsersReact from './users.react';
import ProjectsReact from './projects.react';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import useMaxWidth from 'src/packages/hooks/useMaxWidth';

export default function(router:RouteComponentProps<{id?:string}>) {
    if (!session.User.get("cms").get("can_view_console")) {
        history.push("/");
        return null;
    }

    return (
        <div {...{
            className: css(styles.page)
        }}>
            <Header />
            <Console {...{ page: router.match.params.id || "empty" }} />
            <Footer />
        </div>
    )
}

function Console({ page }:{ page: string }) {
    const [ Active, SetActive ] = useState(page);
    const IsLess500Width = useMaxWidth(500);

    const OnClickMenu = (type: string) => () => {
        history.push(`/console/${type}`);
        SetActive(type);
    };

    return (
        <div {...{
            className: css(styles.console)
        }}>
            <ul {...{
                className: css(styles.menu)
            }}>
                { session.User.get("cms").get("can_manage_users") && (
                    <li {...{
                        onClick: OnClickMenu("users"),
                        className: css(styles.menuItem, Active === "users" && styles.menuActive)
                    }}>
                        Users
                    </li>
                ) }
                { session.User.get("cms").get("can_manage_projects") && (
                    <li {...{
                        onClick: OnClickMenu("projects"),
                        className: css(styles.menuItem, Active === "projects" && styles.menuActive)
                    }}>
                        Projects
                    </li>
                ) }
                <li {...{
                    className: css(styles.menuLast)
                }}></li>
                { !IsLess500Width && (
                    <li {...{
                        onClick: () => {
                            showLightbox({
                                selector:   "change-password",
                                Component:  ChangePassword,
                                content:    {},
                            })
                        },
                        className: css(styles.menuItem)
                    }}>
                        Change password
                    </li>
                )}
                <li {...{
                    onClick: () => {
                        SignOut();
                        history.push("/login");
                    },
                    className: css(styles.menuItem)
                }}>
                    Sign out
                </li>
            </ul>
            <section>
                { Active === "empty" ? <ProjectsReact/> : null }
                { Active === "users" && <UsersReact /> }
                { Active === "projects" && <ProjectsReact /> }
            </section>
        </div>
    );
}


function ChangePassword({selector, close, content}: ComponentProps) {
    const [ Saving, SetSaving ] = useState(false);
    const [ OldPassword, SetOldPassword ] = useState("");
    const [ NewPassword, SetNewPassword ] = useState("");
    const [ ConfirmPassword, SetConfirmPassword ] = useState("");

    async function SaveNewPassword() {
        if (NewPassword.length < 6) {
            alert("new password is to small")
        } else if (NewPassword !== ConfirmPassword) {
            alert("password does not match")
        } else {
            SetSaving(true);
            const err = await UpdatePassword(OldPassword, NewPassword);
            if (err !== null) {
                alert(err.status_text)
                SetSaving(false);
            } else {
                alert("password is updated");
                SetSaving(false);
                close();
            }
        }
    }

    return (
        <Lightbox {...{ close }}>
            <LBHeader {...{ selector, close }}>
                Change account password
            </LBHeader>
            <Body>
                <Content styles={ styles.pass_lb }>
                    <input {...{
                        placeholder:    "old password",
                        type:           "password",
                        value:          OldPassword,
                        className:      css(styles.input),
                        onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                            SetOldPassword(e.target.value);
                        }
                    }} />
                    <br/>
                    <input {...{
                        placeholder:    "new password",
                        type:           "password",
                        value:          NewPassword,
                        className:      css(styles.input),
                        onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                            SetNewPassword(e.target.value);
                        }
                    }} />
                    <input {...{
                        placeholder:    "confirm new password",
                        type:           "password",
                        value:          ConfirmPassword,
                        className:      css(styles.input),
                        onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                            SetConfirmPassword(e.target.value);
                        }
                    }} />
                </Content>
                <LBFooter styles={ styles.pass_footer }>
                    <button {...{
                        type: "button",
                        className: css(SimpleButton.button({
                            color: SimpleButton.colors.default,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                        })),
                        onClick: close,
                    }}>
                        Close
                    </button>
                    <button {...{
                        type: "button",
                        disabled: Saving,
                        className: css(SimpleButton.button({
                            color: SimpleButton.colors.blue,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                        }), styles.save_btn),
                        onClick: !Saving ? SaveNewPassword : undefined,
                    }}>
                        { Saving ? "Saving" : "Save" }
                    </button>
                </LBFooter>
            </Body>
        </Lightbox>
    );
}

const styles = StyleSheet.create({
    page: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    console: {
        flexGrow: 1,
    },
    menu: {
        display: "flex",
        background: "#0C6DFF",
        listStyle: "none",
        padding: "0 10px",
    },
    menuItem: {
        height: 50,
        lineHeight: "50px",
        color: "white",
        fontWeight: "bold",
        padding: "0 10px",
        cursor: "pointer",
        ":hover": {
            textDecoration: "underline",
        }
    },
    menuActive: {
        textDecoration: "underline",
    },
    menuLast: {
        flexGrow: 1,
        textAlign: "right",
    },
    pass_footer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
    },
    pass_lb: {
        padding: 20,
    },
    input: {
        display: "block",
        margin: "5px 0",
        padding: 5,
        width: "100%",
    },
    save_btn: {
        marginLeft: 10,
    }
})