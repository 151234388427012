import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
    page: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    main: {
        margin: "70px 0",
        flexGrow: 1,
        '@media(max-width: 600px)': {
            margin: "20px 0",
        }
    },
    hero: {
        position: "relative",
        padding: "100px 0",
        backgroundColor: "#0C6DFF",
        overflow: "hidden",
    },
    heroBack: {
        position: "absolute",
        display: "block",
        right: 0,
        width: 650,
        height: 650,
        bottom: -335,
        fill: "#fff",
        opacity: 0.1,
        zIndex: -1,
    },
    heroTitle: {
        margin: 0,
        fontSize: "56px",
        lineHeight: "56px",
        fontWeight: "bold",
        color: "#fff",
    },
    title: {
        margin: "0 0 1.5rem 0",
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
    },
    subTitle: {
        margin: "0 0 1rem 0",
        fontSize: "1.2rem",
    },
    text: {
        margin: "0 0 2rem 0",
    },
    list: {
        marginBottom: "2rem",
        marginLeft: 40,
    }
})