import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    error: {
        flexGrow: 1,
        textAlign: "center",
        padding: "150px 0",
    },
    title: {
        display: "flex",
        fontSize: "2rem",
        alignItems: "center",
        justifyContent: "center",
        '@media(max-width: 600px)': {
            display: "block",
        }
    },
    titleText: {
        '@media(max-width: 600px)': {
            display: "block",
        }
    },
    icon: {
        display: "block",
        width: 30,
        height: 30,
        marginRight: 10,
        fill: "#000",
        '@media(max-width: 600px)': {
            margin: "0 auto",
        }
    }
});