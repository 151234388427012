import React, { useState, Fragment, useEffect, useRef } from 'react';
import { 
    GrowlType,
    listenOnShow,
    unlistenOnShow,
    listenOnHide,
    unlistenOnHide,
    listenOnChange,
    unlistenOnChange 
 } from './events';
import { Styles, TransformLine } from './growl.css';
import { css } from 'aphrodite';
import { setTimeout } from 'timers';

export default function() {
    const timer = useRef<NodeJS.Timeout>();
    const [ Type, SetType ] = useState<GrowlType>(null);
    const [ Progress, SetProgress ] = useState(0);

    function StartTimer() {
        if (!timer.current) {
            let init = Progress;
            timer.current = setInterval(() => {
                init = init === 0 ? 20 : init + 6;
                SetProgress(init);
            }, 50);    
        }
    }

    function StopTimer() {
        if (timer.current) {
            SetProgress(0);
            clearInterval(timer.current);
            timer.current = undefined;
        }
    }

    function OnShow(type:GrowlType) {
        if (Type === null) {
            SetType(type);
            StartTimer();
        }
    }

    function OnHide() {
        StopTimer();
        SetProgress(100);
        setTimeout(() => {
            SetType(null);
            SetProgress(0);
        }, 50);
    }

    function OnChange(progress: number) {
        if (progress > Progress) {
            SetProgress(progress);
        }
    }

    useEffect(() => {
        listenOnShow(OnShow);
        listenOnHide(OnHide);
        listenOnChange(OnChange);

        return () => {
            unlistenOnShow(OnShow);
            unlistenOnHide(OnHide);
            unlistenOnChange(OnChange);
        };
    });

    return (
        <Fragment>
            { Type === "top-line" ? <aside {...{
                className: css(Styles.topLine, TransformLine(Progress) )
            }} /> : null }
        </Fragment>
    )
}