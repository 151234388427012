import * as runtypes from 'runtypes';
import { Record as IRecord, Map } from 'immutable';

export const SessionUserRuntime = runtypes.Record({
    guid:               runtypes.String,
    primary_email:      runtypes.String.Or(runtypes.Null),
    primary_phone:      runtypes.String.Or(runtypes.Null),
    first_name:         runtypes.String,
    last_name:          runtypes.String,
    verified:           runtypes.Boolean,
    is_paid:            runtypes.Boolean,
    cms:                runtypes.Dictionary(runtypes.Boolean, "string"),
});

export type SessionUserRuntime = runtypes.Static<typeof SessionUserRuntime> & {

}

interface SessionUserRecord extends Omit<SessionUserRuntime, "cms" | "regions"> {
    cms:            Map<string, boolean>
}

export const SessionUser = IRecord<SessionUserRecord>({
    guid:               "",
    primary_email:      "",
    primary_phone:      null,
    first_name:         "",
    last_name:          "",
    verified:           false,
    is_paid:            false,
    cms:                Map(),
});

export type SessionUser = IRecord<SessionUserRecord>;

export function MakeUserFromRuntime(data:SessionUserRuntime):IRecord<SessionUserRecord> {
    return SessionUser({...data, ...{
        cms:            Map(data.cms),
    }});
}
