import { StyleSheet } from 'aphrodite';
import { coralRed, darkGray, brandBlue } from 'src/styles/colors';

export default StyleSheet.create({
    tg: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
        position: "relative",
    },
    box: {
        width: "100%",
        height: 400,
        padding: "20px",
    },
    logo: {
        width: 300,
        margin: "0 auto",
    },
    logoCrunchy: {
        width: 215,
        margin: "0 auto",
    },
    body: {
        backgroundColor: "white",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    lightbox: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    lightboxContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
    },
    successTitle: {
        margin: "0 0 1rem 0",
        fontSize: "3rem",
    },
    inputs: {
        flexGrow: 1,
        padding: "20px 20px 50px 20px",
        textAlign: "center",
    },
    input: {
        display: "block",
        width: "100%",
        height: 40,
        padding: "0 10px",
        fontSize: "1rem",
        color: "#000",
        backgroundColor: "#fff",
        border: `1px solid ${darkGray}`,
        borderRadius: 6,
        boxShadow: 0,
        outline: 0,
        flexGrow: 1,
        transition: 'border  .15s ease-in-out',
        ':focus': {
            outline: 0,
            boxShadow: 0,
        }
    },
    inputMargin: {
        marginTop: "30px",
    },
    inputLabel: {
        display:    "block",
        textAlign:  "left",
        marginTop:  10,
        fontSize:   "12px",  
    },
    inputError: {
        border: `1px solid ${coralRed}`,
        ':focus': {
            border: `1px solid ${coralRed}`,
        }
    },
    error: {
        position: "absolute",
        width: "100%",
        margin: "0.75rem 0",
        fontSize: "0.75rem",
        color: coralRed,
    },
    resetBlock: {
        display: "flex",
        justifyContent: "flex-end",
    },
    reset: {
        display: "block",
        padding: "5px 0 0 0",
        fontSize: "0.75rem",
        color: darkGray,
        textDecoration: "none",
        border: 0,
        boxShadow: 0,
        outline: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        ':focus': {
            border: 0,
            outline: 0,
            boxShadow: 0,
        },
        ':hover': {
            textDecoration: "underline",
        }
    },
    bottom: {
        display: "flex",
        padding: "0 20px",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        '@media(max-width: 650px)': {
            flexDirection: "column",
            alignItems: "flex-end",
        },
    },
    bottomCenter: {
        display: "flex",
        padding: "0 20px",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomSign: {
        display: "flex",
        alignItems: "center",
        '@media(max-width: 650px)': {
            justifyContent: "flex-end",
        },
    },
    create: {
        display: "block",
        padding: 0,
        fontSize: "1rem",
        color: brandBlue,
        backgroundColor: "#fff",
        textDecoration: "underline",
        border: 0,
        boxShadow: 0,
        outline: 0,
        cursor: "pointer",
        ':focus': {
            border: 0,
            outline: 0,
            boxShadow: 0,
        },
        '@media(max-width: 650px)': {
            fontSize: "0.75rem",
            marginTop: 20,
        },
    },
    show: {
        opacity: 1,
        transition: 'opacity  .3s ease-in-out',
    },
    hide: {
        opacity: 0,
        transition: 'opacity  .3s ease-in-out',
    }
})