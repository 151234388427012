import { StyleSheet } from 'aphrodite';
import { gray, lightGray, darkGray } from 'src/styles/colors';

interface config {
    size: number,
    width: number|undefined|"full"
    maxWidth: number|undefined
    withBorder: boolean|undefined,
    headerFontSize: number|undefined,
    bodyFontSize: number|undefined,
    headerBGColor: string|undefined,
    headerlineHeight: number|string|undefined,
}

export function MakeTableCSS(cfg:config) {
    return StyleSheet.create({
        container: {
            width: typeof cfg.width == "number" ? cfg.width : "100%",
            maxWidth: typeof cfg.maxWidth == "number" ? cfg.maxWidth : "auto",
            border: cfg.withBorder ? `1px solid ${gray}` : "none",
            borderRadius: 5,
        },
        title: {
            fontSize: 16,
            borderBottom: `1px solid ${gray}`,
            padding: "10 0", 
        },
        table: {
            width: "100%",
        },
        trow: {
            display: "flex",
        },
        thead: {
            backgroundColor: cfg.headerBGColor ? cfg.headerBGColor : lightGray,
            lineHeight: cfg.headerlineHeight ? cfg.headerlineHeight : "normal",
        },
        tbody: {

        },
        theadColumn: {
            textAlign: "left",
            padding: "2px 10px",
            fontSize: cfg.headerFontSize ? cfg.headerFontSize : 14,
        },
        tbodyColumn: {
            borderTop: `1px solid ${darkGray}`,
            padding: "2px 10px",
            fontSize: cfg.bodyFontSize ? cfg.bodyFontSize : 14,
        }
    })
}

export function MakeColumnStyles(size:number|null|"auto") {
    return StyleSheet.create({
        base: {
            width: typeof size == "number" ? size : "auto",
            maxWidth: typeof size == "number" ? size : "auto",
        },
    }).base
}