import * as runtypes from 'runtypes';
import { SessionUserRuntime } from './SessionUser';
import { SessionRuntimeType } from './SessionRuntime';

export const SessionAPIRuntime = runtypes.Record({
    user:       SessionUserRuntime,
    runtime:    SessionRuntimeType,
})

export type SessionAPIRuntime = runtypes.Static<typeof SessionAPIRuntime> & {
    runtime: SessionRuntimeType
}