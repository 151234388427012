import React, { useState } from 'react';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
// models
import { PagesList, Page } from './model';
// components
import { Lightbox, Body } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
// parts
import SignUp from './signup.react';
import ResetPassword from './reset_password.react';
import ResetPasswordConfirm from './reset_password_confirm.react';
import ResetPasswordSuccess from './reset_password_success.react';
// icons
import Logo from './logo.react';
// styles
import { css } from 'aphrodite';
import Styles from './login.css';

const Pages:Record<PagesList, Page> = {
    "sign-in": {
        Component:  SignUp,
        Marker:     "sign-in",
    },
    "sign-up": {
        Component:  SignUp,
        Marker:     "sign-up",
    },
    "reset": {
        Component:  ResetPassword,
        Marker:     "reset",
    },
    "reset-pass-confirm": {
        Component:  ResetPasswordConfirm,
        Marker:     "reset-pass-confirm",
    },
    "reset-pass-success": {
        Component:  ResetPasswordSuccess,
        Marker:     "reset-pass-success",
    }
};

export default function({ selector, close }:ComponentProps<any>) {
    let defaultPage: Page = Pages["sign-in"];
    if (selector === 'sign-up' || selector === 'reset') {
        defaultPage = Pages[selector];
    }

    const [ Page, SetPage ] = useState<Page>(defaultPage);
    const [ FadeType, SetFadeType ] = useState("switch-login-right");

    function SetPageMyMarker(marker:PagesList) {
        if (Pages[marker]) {
            if (
                (Page.Marker === "reset" && marker !== "reset-pass-confirm") ||
                Page.Marker === "reset-pass-success"
            ) {
                SetFadeType("switch-login-left");
            } else {
                SetFadeType("switch-login-right");
            }

            setTimeout(() => {
                SetPage(Pages[marker]);
            }, 100);
        }
    }

    return (
        <Lightbox styles={ Styles.box }>
            <Logo />
            <TransitionGroup className={ css(Styles.tg) }>
                <CSSTransition {...{
                    classNames: FadeType,
                    key:        Page.Marker,
                    timeout:    300,
                }}>
                    <Body styles={ Styles.body }>
                        <Page.Component {...{
                            SetPage:    SetPageMyMarker,
                            Page:       Page,
                            close:      close,
                        }} />
                    </Body>
                </CSSTransition>
            </TransitionGroup>
        </Lightbox>
    );
}