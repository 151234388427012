import router from "config/router.json";
import { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// components
import Landing from "src/view/landing/landing.react";
import Page404React from "src/view/errors/page404.react";
import Privacy from "src/view/privacy/privacy.react";
// import AI from "src/view/ai/ai.react";
// import Scale from "src/view/scale/scale.react";
import SignIn from 'src/view/session/login.page.react';
import Console from 'src/view/console/console.page.react';

export interface Page {
    path:       string
    file:       string
    exact:      boolean
    sidebar?:    boolean
    component:  ComponentType<RouteComponentProps<any>>
}

const pages:Page[] = [];

router.pages.forEach(page => {
    let component:ComponentType<RouteComponentProps<any>> = Landing;

    switch (page.path) {
        case "404":
            component = Page404React;
            break;

        case "privacy":
            component = Privacy;
            break;

        // case "machine-learning":
        //     component = AI;
        //     break;

        // case "scale":
        //     component = Scale;
        //     break;

        case "login":
            component = SignIn;
            break;

        case "console/:id?":
            component = Console;
            break;

        default:
            break;
    }

    pages.push({
        ...page,
        component,
    })
})

export { pages };