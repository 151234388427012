import React, { ComponentType, Fragment } from 'react';
import { css } from 'aphrodite';
import { MakeTableCSS, MakeColumnStyles } from './PrettyTable.css';

interface props {
    title?:            string|ComponentType
    thead?:            Array<string|number|undefined|null|ComponentType|JSX.Element>
    tbody:             Array<Array<string|number|undefined|null|ComponentType|JSX.Element>>
    columnsSizes?:     Array<number|"auto"|null>
    width?:            number|"full",
    maxWidth?:         number,
    withBorder?:       boolean,
    headerFontSize?:   number,
    bodyFontSize?:     number,
    headerBGColor?:    string,
    headerlineHeight?: number | string,
}

// !!!
// PLEASE DON'T JUST CHANGE STYLES
// YOU CAN MAKE THEM CONFIGURABLE
// E.G. IF YOU NEED TO HIDE BORDER
// MAKE A NEW FLAG INSIDE CFG
// AND CHANGE MakeTableCSS FUNCTION

export default function({
    title,
    thead,
    tbody,
    columnsSizes,
    width,
    maxWidth,
    withBorder,
    headerFontSize,
    bodyFontSize,
    headerBGColor,
    headerlineHeight,
}:props) {
    const TSize = tbody.length > 0 ? Math.max(...tbody.map(trows => trows.length)) : 0;
    const Styles = MakeTableCSS({
        size: TSize,
        width,
        maxWidth,
        withBorder,
        headerFontSize,
        headerBGColor,
        bodyFontSize,
        headerlineHeight,
    });

    const Title = title;
    
    return (
        <section className={ css(Styles.container) }>
            { Title ? typeof Title == "string" ? (
                <h2 className={ css(Styles.title) }>{ Title }</h2>
            ) : <Title /> : null }
            <table className={ css(Styles.table) } cellPadding="0" cellSpacing="0" >
                <thead className={ css(Styles.thead) } >
                    <tr>
                        { thead && thead.map((HColumn, key) => (
                            <th {...{
                                key,
                                className: css(
                                    MakeColumnStyles(columnsSizes && columnsSizes[key] ? columnsSizes[key] : "auto"),
                                    Styles.theadColumn,
                                )
                            }}>
                                { HColumn ? (
                                    <Fragment>
                                        { typeof HColumn == "string" || typeof HColumn == "number" || typeof HColumn !== "function" ? HColumn : <HColumn /> }
                                    </Fragment>
                                ) : "" }
                            </th>
                        )) }
                    </tr>
                </thead>
                <tbody className={ css(Styles.tbody) } >
                    { tbody && tbody.map((TColumns, key) => (
                        <tr key={key}>
                            { [...new Array(TSize)].map((k,i) => {
                                const TColumn = TColumns[i];
                                return (
                                    <td {...{
                                        className: css(
                                            MakeColumnStyles(columnsSizes && columnsSizes[i] ? columnsSizes[i] : "auto"),
                                            Styles.tbodyColumn,
                                        ),
                                        key: i,
                                    }}>
                                        { TColumn || TColumn === 0 ? (
                                            <Fragment>
                                                { typeof TColumn == "string" || typeof TColumn == "number" ? (
                                                    <Fragment>
                                                        { TColumn }
                                                    </Fragment>
                                                ) : (
                                                    typeof TColumn === "function" ? <TColumn /> : TColumn
                                                )}
                                            </Fragment>
                                        ) : "" }
                                    </td>
                                )
                            } ) }
                        </tr>
                    ))}          
                </tbody>
            </table>
        </section>
    )
}