import React, { useState } from 'react';
import InputMask from 'react-input-mask';
// models
import { PropsType } from './model';
import { ResetPassword } from 'src/runtime/session';
// components
import { Content, Footer } from 'src/view/components/lightbox/lightbox.react';
import LoadButton from 'src/view/components/buttons/LoadButton.react';
// styles
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import { css } from 'aphrodite';
import Styles from './login.css';


export default function({SetPage}:PropsType) {
    const [ code, SetCode ] = useState<string>("");
    const [ badCode, SetBadCode ] = useState(false);
    const [ showPass, SetShowPass ] = useState(false);
    const [ password, setPassword ] = useState<string>("");
    const [ passwordError, setPasswordError ] = useState<boolean>(false);
    const [ IsSending, SetIsSending ] = useState(false);

    async function onClickContinue() {
        if (code.trim() === '') {
            SetBadCode(true);
            return null;
        }

        if (password.trim() === '' || password.length < 6) {
            setPasswordError(true);
            return null;
        }

        SetIsSending(true);

        const err = await ResetPassword(code, password);

        SetIsSending(false);
        if (err != null) {
            SetBadCode(true);
            return null
        }

        SetPage("reset-pass-success")
    }

    function onKeyPress(e:React.KeyboardEvent) {
        if(e.key === 'Enter'){
            e.preventDefault();
            onClickContinue();
        }
    }

    function onChangeCode(e:React.ChangeEvent<HTMLInputElement>) {
        SetBadCode(false);
        SetCode(e.target.value);

        if (parseInt(e.target.value).toString().length === 6) {
            SetShowPass(true);
        }
    }

    function onChagePass(e:React.FormEvent<HTMLInputElement>) {
        setPasswordError(false);
        setPassword(e.currentTarget.value);
    }

    return (
        <section className={ css(Styles.form) }>
            <Content styles={ Styles.inputs }>
                Email with verification code successfully sent, please enter code below
                <label className={ css(Styles.inputLabel, Styles.inputMargin) }>
                    Verification code
                </label>
                <InputMask {...{
                    mask:       "999999",
                    value:      code,
                    onChange:   onChangeCode,
                    className:  css(Styles.input),
                    onKeyPress
                }} />
                <label className={css(
                    Styles.inputLabel,
                    showPass ? Styles.show : Styles.hide,
                )}>
                    New password
                </label>
                <input {...{
                    onChange:       onChagePass,
                    placeholder:    "Password",
                    type:           "password",
                    name:           "password",
                    value:          password,
                    autoComplete:   "current-password",
                    onKeyPress,
                    className:      css(
                        Styles.input,
                        passwordError && Styles.inputError,
                        showPass ? Styles.show : Styles.hide,
                    )
                }} />
                { passwordError ? (
                    <section className={css(Styles.error)}>
                        Password must have at least 6 symbols 
                    </section>
                ) : null }
                { badCode ? (
                    <section className={css(Styles.error)}>
                        Code is not correct
                    </section>
                ) : null }
            </Content>
            <Footer styles={Styles.bottomCenter}>
                <LoadButton {...{
                    type: "button",
                    text: "Save new password",
                    styleConfig: {
                        color:          SimpleButton.colors.blue,
                        uppercase:      true,
                        fontSize:       "0.75rem",
                        fontWeight:     "bold",
                        height:         45,
                        borderRadius:   3,
                    },
                    onClick:    onClickContinue,
                    loading:    IsSending,
                    disabled:   !showPass,
                }}/>
            </Footer>
        </section>
    )
}