import React  from 'react';
import { Lightbox, Header as LBHeader, Body, Content } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
// comoponents
import Form from './form.react';

export default function ({ selector, close }: ComponentProps) {
    return (
        <Lightbox {...{ close }}>
            <LBHeader {...{ selector, close }}>
                Contact Us
            </LBHeader>
            <Body>
                <Content>
                    <Form source="lightbox" />
                </Content>
            </Body>
        </Lightbox>
    )
}