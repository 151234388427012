import { get } from 'src/utils/api';
import { ConsoleUsers } from 'src/models/ConsoleUser';

export default async function (date?: number): Promise<ConsoleUsers> {
    const [res, err] = await get<ConsoleUsers>({
        url: "/console/users",
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    ConsoleUsers.check(res)

    return res;
}