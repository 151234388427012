export const white = "#FFF"
export const salad = "#56B04F"
export const darkerSalad = "#499744"
export const darkenSalad = "#42883D"
export const alto = "#DADADA"
export const mercury = "#E7E7E7"
export const blue = "#215d9b"
export const lightBlue = "#4586FF"
export const brandBlue = "#062764";
export const darkenBrandBlue = "#041e4f";
export const darkGray = "#5B5855";
export const darkenGray = "#535353";
export const coralRed = "#FF8578";
export const darkenColarRed = "#d73434";
export const lightGray = "rgba(196, 196, 196, 0.4)";
export const crunchyBlue = "#205DD2";
export const gray = "#F0F0F0";
export const black = "#02291C";
export const green = "#36D61C";
export const yellow = "#FFCA29";
export const orange = "#FE8578";