import React from "react";
// icons
import { ReactComponent as Success } from 'src/icons/cross.svg';
import { ReactComponent as Failure } from 'src/icons/cross.svg';
// styles
import { css } from "aphrodite";
import Buttons, { Config, verifyConfig } from 'src/view/components/buttons/SimpleButton.scss';
import Styles, { Loader, Path } from "./LoadBtn.scss";
import { Colors } from "./Colors.scss";

export default function({
    styleConfig,
    onClick,
    loading,
    success,
    failure,
    disabled,
    text,
    icon,
    type,
}:{
    styleConfig: Config,
    onClick?: (e:React.MouseEvent) => void,
    loading: boolean,
    success?: boolean,
    failure?: boolean,
    disabled?: boolean,
    text: string,
    icon?: React.ReactNode,
    type?: "reset" | "button" | "submit" | undefined,
}) {
    function click(e:React.MouseEvent) {
        if (!loading && !disabled && typeof onClick === "function") {
            onClick(e)
        }
    }

    let btnColor = styleConfig.color || Colors.green;
    if (success) {
        btnColor = Colors.green;
    } else if (failure) {
        btnColor = Colors.red;
    }

    styleConfig.color = btnColor;

    const buttonConfig = verifyConfig(styleConfig || {});

	return (
        <button  {...{
            type: type ? type : "button",
            className: css(
                Buttons.button(buttonConfig),
                (loading || success || failure) && Styles.defaultCursor,
                typeof icon !== 'undefined' && Styles.withIcon,
                disabled && Styles.disabled,
            ),
            onClick: click,
        }}>
            <span className={css(
                Styles.text,
                (loading || success || failure) && Styles.hiddenText,
                (typeof icon !== 'undefined' && !loading && !success && !failure) && Styles.textIcon
            )}>
                {icon ? <span className={css(Styles.icon)}>{ icon }</span> : null}
                {text}
            </span>
            {loading &&
                <div className={css(Styles.block)}>
                    <div className={css(Loader(buttonConfig.height))}>
                        <svg className={css(Styles.circle)} viewBox="25 25 50 50">
                            <circle  {...{
                                className: css(Path(buttonConfig.color?.color)),
                                cx: 50,
                                cy: 50,
                                r: 20,
                                fill: "none",
                                strokeWidth: 2,
                                strokeMiterlimit: 10,
                            }}/>
                        </svg>
                    </div>
                </div>
            }
            {success ? (
                <div className={css(Styles.block)}>
                    <Success className={css(Styles.success)} width="25" height="25" />
                </div>
            ) : null}
            {failure ? (
                <div className={css(Styles.block)}>
                    <Failure className={css(Styles.failure)} width="25" height="25" />
                </div>
            ) : null}
        </button>
    )
}