import { put, ErrorResponse } from 'src/utils/api';

export default async function (OldPassword: string, NewPassword: string):Promise<ErrorResponse<any> | null> {
    const [ , err ] = await put({
        url: "session/passwords",
        body: {
            old_password:   OldPassword,
            password:       NewPassword,
        }
    });

    return err
}