import { get } from 'src/utils/api';
import { SessionAPIRuntime } from 'src/models/Session';

export default async function():Promise<[SessionAPIRuntime, string | null ] > {
    const [ res, err, CSRFToken ] = await get<SessionAPIRuntime>({ url: "session"});
    if (err != null) {
        throw new Error(err.status_text);
    }

    SessionAPIRuntime.check(res)
    
    return [ res, CSRFToken ];
}