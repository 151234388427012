import React, { useEffect, useRef, useLayoutEffect, useState, useMemo, Fragment } from 'react';
import { OrderedMap } from 'immutable';
import EventEmitter from 'eventemitter3';
import { Config, overlayProps, ComponentProps, LightboxProps, HeaderProps} from './lightbox.model.d';
import { css } from 'aphrodite';
import { Styles, GetZIndex } from './lightbox.css';
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';
import { IS_SSR } from "src/utils/ssr";
import Portal from 'src/view/components/portal/portal.react';

export type { ComponentProps } from './lightbox.model.d';
export { default as showWarning } from './warning.react'

/**
 * showLightbox is a function that shows react element as a lightobx
 *
 * @param {string}                                      selector                    Unique selector for each lightbox.
 * @param {ReactFunctionComponent}                      Component                   React component that will be rendered.
 * @param {content}                                     content                     Content that will be passed as props.data to Component(optinonal)
 * @param {generic}                                     C                           Type of content(optinonal).
 * @param {boolean}                                     disableAnimation            Disables show/hide animation.
 * @param {boolean}                                     preventCloseOnESC           Prevent close on ESC press.
 * @param {boolean}                                     preventCloseOnOverlay       Prevent close on overlay click.
 * @param {boolean}                                     preventCloseOnPopstate      Prevent close on popstate.
 * @param {boolean}                                     preventInternalClose        Prevent all internal close triggers.
 * @param {boolean}                                     disableDefaultStyles        Disables all default styles.
 * @param {object}                                      styles                      list of styles that will extend default styles.
 * @param {StyleDeclarationValue}                       dom                         styles of first element in the dom of lightbox.
 * @param {StyleDeclarationValue}                       overlay                     styles for lightbox overlay.
 * @param {StyleDeclarationValue}                       wrapper                     styles for lightbox wrapper.
 * @param {object}                                      baseStyles                  list of styles that will overwrite default styles.
 * @param {StyleDeclarationValue}                       overlay                     styles for lightbox overlay.
 * @param {StyleDeclarationValue}                       wrapper                     styles for lightbox wrapper.
 * 
 */

const Events = new EventEmitter()
let ActiveBoxes = OrderedMap<string, Config<any>>();

export async function showLightbox<C = never>(config:Config<C>):Promise<void> {
    new Promise((resolve, reject) => {
        if (IS_SSR) {
            reject();
        }

        if (ActiveBoxes.has(config.selector)) {
            console.error(`loader.show_lightbox: lightbox with this selector (${config.selector}) already rendered`);
            return
        }

        ActiveBoxes = ActiveBoxes.set(config.selector, config);
        Events.emit("rerender-boxes");

        resolve();
    });
}

export async function closeLightbox(selector: string, onClose?:(selector?: string) => void):Promise<void> {
	if (typeof onClose === 'function') {
		onClose(selector);
	}

    ActiveBoxes = ActiveBoxes.remove(selector);
    Events.emit("rerender-boxes");
    
    return;
}

export function Renderer() {
    return useMemo(() => (
        <RendererMemo />
    ), [])
}

function RendererMemo() {
    const [ boxes, setBoxes ] = useState(OrderedMap<string, Config<any>>());

    function onRerender() {
        setBoxes(ActiveBoxes);
    }

    useEffect(() => {
        onRerender()
    }, [])

    useEffect(() => {
        Events.on("rerender-boxes", onRerender);

        return () => {
            Events.removeListener("rerender-boxes", onRerender);
        }
    });

    const triggerClose = (config:Config<any>) => () => {
        closeLightbox(config.selector, config.onClose);
    }

    return (
        <Fragment>
            { boxes.toList().map((box, index) => (
                <Portal key={box.selector} styles={box.styles?.dom}>
                    <Overlay {...box} {...{ 
                        close:  triggerClose(box), 
                        order:  index
                    }} />
                </Portal>
            )) }
        </Fragment>
    );
}

export function Overlay<D>({
    close,
    order,
    styles,
    content,
    children,
    selector,
    Component,
    baseStyles,
    disableAnimation,
    disableDefaultStyles,
    preventCloseOnESC,
    preventCloseOnOverlay,
    preventCloseOnPopstate,
    preventInternalClose,
}:overlayProps<D>) {
    const wrapper = useRef<HTMLDialogElement>(null)
    const [ show, setShow ] = useState(disableAnimation);

    function onKeydown(e:KeyboardEvent) {
        if (!preventInternalClose && !preventCloseOnESC) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }
    }

    function onClickOverlay(e:React.MouseEvent) {
        if (!preventInternalClose && !preventCloseOnOverlay) {
            if (wrapper.current && e.target instanceof HTMLElement) {
                if (!wrapper.current.contains(e.target)) {
                    handleClose();
                }
            }
        }
    };

    function onPopstate() {
        if (!preventInternalClose && !preventCloseOnPopstate) {
            handleClose();
        }
    }

    function handleClose() {
        if (disableAnimation) {
            close();
        } else {
            setShow(false);
            setTimeout(() => {
                close();
            }, 200);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeydown);
        window.addEventListener("popstate", onPopstate);
        return () => {
            window.removeEventListener('keydown', onKeydown);
            window.removeEventListener("popstate", onPopstate);
        };
    });

    useLayoutEffect(() => {
        if (!disableAnimation) {
            setTimeout(() => {
                setShow(true);
            }, 50);
        }
    }, [ disableAnimation ]);

    const baseCSS = {
        overlay:    baseStyles && baseStyles.overlay ? baseStyles.overlay : !disableDefaultStyles ? Styles.overlay : null,
        wrapper:    baseStyles && baseStyles.wrapper ? baseStyles.wrapper : !disableDefaultStyles ? Styles.wrapper : null,
    };

    const componentProps:ComponentProps<D> = {
        close:      handleClose,
        selector:   selector,
        content:    content,
    }

    return (
        <aside {...{
            id:         `_lightbox-${selector}`,
            className:  css(
                baseCSS.overlay,
                GetZIndex(order),
                styles && styles.overlay ? styles.overlay : null,
                show ? Styles.showOverlay : null,
            ),
            onMouseDown:    onClickOverlay,
        }}  >
            <section {...{
                id:                 `_lightbox-${selector}-wrapper`,
                ref:                wrapper,
                role:               "dialog",
                "aria-labelledby":  `_lightbox-${selector}-label`,
                "aria-modal":       true,
                className:  css(
                    baseCSS.wrapper,
                    styles && styles.wrapper ? styles.wrapper : null,
                    show ? Styles.showWrapper : null,
                ),
            }} >
                <Component {...componentProps} />
            </section>
        </aside>
    )
}

export function Lightbox({ styles, ...props }:LightboxProps) {
    return (
        <article {...props} {...{
            className: css(Styles._lightbox, styles ? styles : null)
        }}>
            { props.children }
        </article>
    )
}

export function Body({ styles, ...props }:LightboxProps) {
    return (
        <section {...props} {...{
            className: css(Styles._body, styles ? styles : null)
        }}>
            { props.children }
        </section>
    )
}

export function Header({ close, selector, styles, ...props }:HeaderProps) {
    return (
        <header {...props} {...{
            id: selector ? `_lightbox-${selector}-label` : undefined,
            className: css(
                Styles._header,
                typeof close == "function" ?  Styles._headerWithClose : null,
                styles ? styles : null,
            ),
        }}>
            { props.children }
            { typeof close == "function" ? (
                <aside className={ css(Styles._headerClose) } onClick={close}>
                    <CrossIcon width="20" height="20" />
                </aside>
            ) : null }
        </header>
    )
}

export function Content({ styles, ...props }:LightboxProps) {
    return (
        <section {...props} {...{
            className: css(Styles._content, styles ? styles : null)
        }}>
            { props.children }
        </section>
    )
}

export function Footer({ styles, ...props }:LightboxProps) {
    return (
        <footer {...props} {...{
            className: css(Styles._footer, styles ? styles : null)
        }}>
            { props.children }
        </footer>
    )
}