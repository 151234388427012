import React, { useEffect, Fragment, useState } from 'react';
import { history } from 'src/utils/history';
import { pages, Page } from 'src/utils/pages';
import { 
    LoadSession,
    listenOnSignIn,
    listenOnSignOut,
    unlistenOnSignIn,
    unlistenOnSignOut,
} from 'src/runtime/session';
import {
	Router,
	Route,
	Switch,
    RouteComponentProps,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AfterLoadCheck from 'src/modifiers/global/afterLoadCheck';
import { Renderer } from 'src/view/components/lightbox/lightbox.react';
import { ReConnect } from 'src/utils/socket';
import Growl from 'src/view/components/growls/growls.react';
// styles
import 'src/styles/base.css';
import 'src/styles/toasts.css';

const Page404 = pages.find(page => page.path === "404");

export default function() {
    const [ loading, setLoading ] = useState(true);
    const [ reload, setReload ] = useState(false);

    useEffect(() => {
        async function init() {
            try {
                await LoadSession();
                setLoading(false);
                AfterLoadCheck();
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, []);

    function onSessionChange() {
        ReConnect();
        setReload(!reload);
    }

    useEffect(() => {
        listenOnSignIn(onSessionChange)
        listenOnSignOut(onSessionChange)

        return () => {
            unlistenOnSignIn(onSessionChange)
            unlistenOnSignOut(onSessionChange)
        }
    });

    if (loading) {
        return null;
    }

    return (
        <Fragment>
            <Renderer />
            <ToastContainer />
            <Growl />
            <Router history={ history } >
                <Switch>
                    {pages.map(page => {
                        if (!page.sidebar) {
                            return (
                                <Route {...{
                                    key: page.path,
                                    path: `/${page.path}`,
                                    exact: page.exact,
                                    render: props => (<PageLoader {...{ page }} {...props} />),
                                }} />
                            )
                        }
                        return null;
                    })}
                    {Page404 ? (
                        <Route {...{
                            render: props => (<PageLoader {...{ page: Page404 }} {...props} />),
                        }} />
                    ) : null}
                    </Switch>
            </Router>
        </Fragment>
    );
}

function PageLoader({
    page,
    ...rest
}: RouteComponentProps<any> & { page: Page }):React.ReactElement {
    const Component = page.component;
    return <Component {...page} {...rest} />;
}