import React from 'react';
// components
import Header from 'src/view/components/header/header.react';
import Footer from 'src/view/components/footer/footer.react';
// icons
import { ReactComponent as Logo } from 'src/icons/logo.svg';
// styles
import { css } from 'aphrodite';
import Grid from 'src/styles/grid.css';
import Styles from './privacy.css';

export default function() {
    return (
        <article className={css(Styles.page)}>
            <Header />
            <div className={css(Styles.hero)}>
                <div className={css(Grid.container)}>
                    <div className={css(Grid.row)}>
                        <div className={css(Grid.col12)}>
                            <h1 className={css(Styles.heroTitle)}>Say hello to your new team</h1>
                            <Logo className={css(Styles.heroBack)} />
                        </div>
                    </div>
                </div>
            </div>
            <section className={css(Styles.main)}>
                <div className={css(Grid.container)}>
                    <div className={css(Grid.row)}>
                        <div className={css(Grid.col12)}>
                            <h3 className={css(Styles.title)}>Privacy Policy</h3>
                            <p className={css(Styles.text)}>We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <a href="mailto:info@flap.gg">info@flap.gg</a></p>
                            <p className={css(Styles.text)}>This Privacy Policy governs the privacy policies and practices of our Website, located at PrivacyPolicies.com.</p>

                            <h3 className={css(Styles.title)}>Information we (8th Byte website and services) Collect</h3>
                            <p className={css(Styles.text)}>As a Visitor, you can browse our Website to find out more about our Website. You are not required to provide us with any personal information as a Visitor.</p>

                            <h3 className={css(Styles.title)}>Information You Provide to Us</h3>
                            <p className={css(Styles.text)}>We collect your personal information when you register with us ("User"), when you express an interest in obtaining information about us or our products and services, when you participate in activities on our Website Site (such as using our builder) or otherwise contacting us.</p>
                            <p className={css(Styles.text)}>Generally, you control the amount and type of information you provide to us when using our Website. The personal information that we (8th Byte website and services) collect depends on the context of your interaction with us and the Website, the choices you make and the products and features you use. The personal information we (8th Byte website and services) collect can include the following:</p>
                            <h4 className={css(Styles.subTitle)}>
                                Name, Email Address and Contact Data
                                <div>Payment Information.</div>
                            </h4>
                            <p className={css(Styles.text)}>If you choose to buy one of our Policy, we (8th Byte website and services) collect payment information through our payment processor. All payment information is securely stored by our payment processor and we (8th Byte website and services) do not have access to your sensitive information, such as credit card number or security code.</p>
                            <h4 className={css(Styles.subTitle)}>Business Information.</h4>
                            <p className={css(Styles.text)}>If you choose to create a Policy on our Website, we (8th Byte website and services) may ask specific information related to your business in order to create the Policy.</p>
                            <h4 className={css(Styles.subTitle)}>Automatically Collected Information</h4>
                            <p className={css(Styles.text)}>When you use our Website, we (8th Byte website and services) automatically collect certain computer information by the interaction of your mobile phone or web browser with our Website. Such information is typically considered non-personal information. we (8th Byte website and services) also collect the following:</p>
                            <h4 className={css(Styles.subTitle)}>Cookies</h4>
                            <p className={css(Styles.text)}>Our Website uses "Cookies" to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. we (8th Byte website and services) use Cookies to personalize the Content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. we (8th Byte website and services) never place Personally Identifiable Information in Cookies.</p>
                            <h4 className={css(Styles.subTitle)}>Log Information</h4>
                            <p className={css(Styles.text)}>We automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our Website, if any, as well as the name of the website to which you're headed when you leave our website. This information also includes the IP address of your computer/proxy server that you use to access the Internet, your Internet Website provider name, web browser type, type of mobile device, and computer operating system. We (8th Byte website and services) use all of this information to analyze trends among our Users to help improve our Website.</p>
                            <h3 className={css(Styles.title)}>Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h3>
                            <p className={css(Styles.text)}>For the purpose of this Privacy Policy, we (8th Byte website and services) are a Data Controller of your personal information.</p>
                            <p className={css(Styles.text)}>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we (8th Byte website and services) collect and the specific context in which we (8th Byte website and services) collect it. we (8th Byte website and services) may process your personal information because:</p>
                            <p className={css(Styles.text)}>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:</p>
                            <ul className={css(Styles.list)}>
                                <li>The right to access, update or to delete the personal information we (8th Byte website and services) have on you</li>
                                <li>The right of rectification</li>
                                <li>The right to object</li>
                                <li>The right of restriction</li>
                                <li>The right to data portability</li>
                                <li>The right to withdraw consent</li>
                            </ul>
                            <p className={css(Styles.text)}>Please note that we (8th Byte website and services) may ask you to verify your identity before responding to such requests.</p>
                            <p className={css(Styles.text)}>You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
                            <p className={css(Styles.text)}>"Do Not Sell My Personal Information" Notice for California consumers under California Consumer Privacy Act (CCPA)</p>
                            <p className={css(Styles.text)}>Under the CCPA, California consumers have the right to:</p>
                            <p className={css(Styles.text)}>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                            <p className={css(Styles.text)}>Request that a business delete any personal data about the consumer that a business has collected.</p>
                            <p className={css(Styles.text)}>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                            <p className={css(Styles.text)}>If you make a request, we (8th Byte website and services) have 30 (thirty) days to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                            <h3 className={css(Styles.title)}>Service Providers</h3>
                            <p className={css(Styles.text)}>We employ third party companies and individuals to facilitate our Website ("Service Providers"), to provide our Website on our behalf, to perform Website-related services or to assist us in analyzing how our Website is used. These third-parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                            <h3 className={css(Styles.title)}>Analytics and third party services</h3>
                            <p className={css(Styles.text)}>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                            <p className={css(Styles.text)}>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google</p>
                            <p className={css(Styles.text)}>Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
                            <p className={css(Styles.text)}>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="http://www.google.com/intl/en/policies/privacy" rel="noopener noreferrer" target="_blank">http://www.google.com/intl/en/policies/privacy/</a></p>

                            <h3 className={css(Styles.title)}>Payments processing</h3>
                            <p className={css(Styles.text)}>We provide paid products and/or services on our Website. In that case, we (8th Byte website and services) use third-party services for payment processing (e.g. payment processors).</p>
                            <p className={css(Styles.text)}>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.</p>

                            <h3 className={css(Styles.title)}>Contacting Us</h3>
                            <p className={css(Styles.text)}>If you have any questions regarding the Privacy Policy you may contact us at 8th Byte</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </article>
    );
}