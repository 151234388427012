import React, {useRef, useState, useEffect, Fragment} from 'react';
import { showLightbox } from 'src/view/components/lightbox/lightbox.react';
// components
import FormLightbox from 'src/view/components/form/formLightbox.react';
import Portal from 'src/view/components/portal/portal.react';
// icons
import { ReactComponent as Logo } from 'src/icons/logo.svg';
import { ReactComponent as Menu } from 'src/icons/menu.svg';
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';
// styles
import { css, StyleDeclaration } from 'aphrodite';
import Grid from 'src/styles/grid.css';
import Styles from './header.css';

export default function () {
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);

    const openMobileMenu = () => {
        setMobileMenu(true);
    }

    const closeMobileMenu = () => {
        setMobileMenu(false);
    }

    const contact = () => {
        showLightbox({
            selector: "contact-lightbox",
            Component: FormLightbox,
            content: {},
        })
    }

    return (
        <header className={css(Styles.header)}>
            <div className={css(Grid.container, Styles.container)}>
                <div className={css(Styles.content)}>
                    <a className={css(Styles.logo)} href="/">
                        <Logo width="70" height="49" />
                    </a>
                    <MenuList menuClassName={Styles.menu} menuItemClassName={Styles.menuLink} />
                    <div className={css(Styles.menuMobile)} onClick={openMobileMenu}>
                        <Menu className={css(Styles.menuMobileIcon)} />
                    </div>
                    <MenuMobile visible={mobileMenu} close={closeMobileMenu} />
                    <div className={css(Styles.menuLink, Styles.contactLink)} onClick={contact}>Contact</div>
                </div>
            </div>
        </header>
    );
}

interface menuListProps {
    menuClassName: StyleDeclaration,
    menuItemClassName: StyleDeclaration,
    isMobile?: boolean,
}

const MenuList = (props: menuListProps) => {
    const {
        menuClassName,
        menuItemClassName,
        isMobile,
    } = props;

    const contact = () => {
        showLightbox({
            selector: "contact-lightbox",
            Component: FormLightbox,
            content: {},
        })
    }

    const scrollToElm = (elm: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        const sectionElm: HTMLElement | null = document.querySelector(elm);

        if (sectionElm) {
            const rect: ClientRect = sectionElm.getBoundingClientRect();
            const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            window.scroll({
                top: rect.top + scrollTop,
                behavior: 'smooth'
            });
        }
    }

    return (
        <ul className={css(menuClassName)}>
            <li className={css(Styles.menuItem)}>
                <span className={css(menuItemClassName)} onClick={scrollToElm('#services')}>Services</span>
            </li>
            <li className={css(Styles.menuItem)}>
                <span className={css(menuItemClassName)} onClick={scrollToElm('#tech')}>Technology</span>
            </li>
            <li className={css(Styles.menuItem)}>
                <span className={css(menuItemClassName)} onClick={scrollToElm('#portfolio')}>Portfolio</span>
            </li>
            {isMobile && (
            <li className={css(Styles.menuItem)}>
                <span className={css(menuItemClassName)} onClick={contact}>Contact</span>
            </li>
            )}
        </ul>
    )
}

interface menuProps {
    visible: boolean,
    close: () => void,
}

const MenuMobile = (props: menuProps) => {
    const {
        visible,
        close,
    } = props;

    const wrapper = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function onClickOverlay(e: any) {
            if (visible) {
                if (wrapper.current) {
                    if (!wrapper.current.contains(e.target)) {
                        close();
                    }
                }
            }
        };

        window.addEventListener('click', onClickOverlay)
        return () => {
            window.removeEventListener('click', onClickOverlay)
        }
    }, [visible, close])

    return (
        <Portal styles={undefined}>
            <div ref={wrapper} className={css(Styles.mobileMenuBlock, visible && Styles.mobileMenuBlockVisible)}>
                {visible ? (
                    <Fragment>
                        <div className={css(Styles.menuClose)} onClick={close}>
                            <CrossIcon className={css(Styles.menuCloseIcon)} />
                        </div>
                        <MenuList {...{
                            menuClassName: Styles.mobileMenuList,
                            menuItemClassName: Styles.mobileMenuLink,
                            onAction: close,
                            isMobile: true,
                        }} />
                    </Fragment>
                ) : null}
            </div>
        </Portal>
    )
}