import ReactGA from 'react-ga';
import config from './config';
import { createBrowserHistory } from 'history';
import { TrackPageView } from './tracking';
import { UpdateDefaultExtraTags } from './extratags';
export const history = createBrowserHistory();

TrackPageView(window.location.pathname)

const trackGA = (loc: any) => {
    if (config.env === config.envs.production) {
        if (loc.pathname.indexOf('/console') === -1) {
            ReactGA.pageview(loc.pathname + loc.search);
        }
    }
}

if (config.env === config.envs.production) {
    ReactGA.initialize('UA-198193072-1');
}

trackGA(window.location);

history.listen((location, action) => {
    TrackPageView(location.pathname)
    UpdateDefaultExtraTags(location.pathname)
})