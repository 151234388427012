import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Product } from 'src/models/Projects';
import { Lightbox, Header, Body, Content, Footer } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';

export default function UsersList({selector, close, content}: ComponentProps<{
    project_name: string,
    product: Product,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header {...{ selector, close }}>
                { content.project_name } - { content.product.name } team
            </Header>
            <Body>
                <Content styles={ styles.users_list }>
                    <table className={ css(styles.users_list_table) }>
                        <thead>
                            <tr>
                                <td><strong>email</strong></td>
                                <td><strong>role</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            { content.product.users.map(user => (
                                <tr key={ user.email }>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </Content>
                <Footer styles={ styles.users_list_footer }>
                    <button {...{
                        type: "button",
                        className: css(SimpleButton.button({
                            color: SimpleButton.colors.default,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                        })),
                        onClick: close,
                    }}>
                        Close
                    </button>
                </Footer>
            </Body>
        </Lightbox>
    );
}


const styles = StyleSheet.create({
    users_list: {
        padding: "0 20px",
    },
    users_list_table: {
        width: "100%",
    },
    users_list_footer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
    },
})