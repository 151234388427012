import { StyleSheet } from 'aphrodite';
import { black, darkGray, gray, orange, yellow } from 'src/styles/colors';

export default StyleSheet.create({
    page: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    main: {
        flexGrow: 1,
    },
    star: {
        position: "absolute",
        backgroundColor: yellow,
        borderRadius: "50%",
    },
    hero: {
        padding: "80px 0",
        "@media(max-width: 767px)": {
            textAlign: "center",
        }
    },
    heroContainer: {
        position: "relative",
    },
    heroStar1: {
        bottom: -40,
        left: "40%",
        width: 10,
        height: 10,
    },
    heroStar2: {
        bottom: 0,
        left: 20,
        width: 5,
        height: 5,
    },
    heroStar3: {
        bottom: 100,
        right: 50,
        width: 5,
        height: 5,
    },
    heroStar4: {
        top: 100,
        left: "60%",
        width: 10,
        height: 10,
    },
    heroImgBlock: {
        position: "relative",
        height: "auto"
    },
    heroImg: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "auto",
        "@media(max-width: 767px)": {
            position: "relative",
            top: "auto",
            right: "auto",
        }
    },
    heroTitle: {
        position: "relative",
        margin: 0,
        fontSize: 48,
        lineHeight: 1.2,
        fontWeight: 700,
        color: black,
        zIndex: 2,
    },
    heroTitleLine: {
        "@media(max-width: 600px)": {
            display: "block",
        }
    },
    heroText: {
        position: "relative",
        margin: "30px 0",
        fontSize: 17,
        lineHeight: "27px",
        color: darkGray,
        zIndex: 2,
    },
    btn: {
        position: "relative",
        padding: "18px 32px",
        fontSize: 17,
        fontWeight: 600,
        color: black,
        textDecoration: "none",
        borderRadius: 8,
        backgroundColor: yellow,
        border: 0,
        outline: 0,
        boxShadow: "none",
        cursor: "pointer",
        zIndex: 2,
        transition: "background-color 0.2s",
        overflow: "hidden",
        ":focus": {
            border: 0,
            outline: 0,
        },
        ":hover": {
            backgroundColor: orange,
        }
    },
    plainBtn: {
        padding: "14px 20px",
        backgroundColor: gray,
        border: `1px solid ${gray}`,
        transition: "background-color 0.2s, border 0.2s",
        ":hover": {
            backgroundColor: "transparent",
            border: `1px solid ${black}`,
        }
    },
    services: {
        padding: "80px 0",
        "@media(max-width: 767px)": {
            textAlign: "center",
        }
    },
    blockTitle: {
        margin: 0,
        fontSize: 38,
        fontWeight: 700,
        color: black,
    },
    servicesTitle: {
        textAlign: "center",
    },
    servicesRow: {
        position: "relative",
        marginTop: 40,
        alignItems: "center",
    },
    serviceStar1: {
        top: 40,
        right: "40%",
        width: 5,
        height: 5,
    },
    serviceStar2: {
        top: 20,
        left: "40%",
        width: 5,
        height: 5,
    },
    serviceStar3: {
        top: 40,
        right: "30%",
        width: 10,
        height: 10,
    },
    serviceStar4: {
        top: 200,
        right: "40%",
        width: 5,
        height: 5,
    },
    serviceStar5: {
        top: 50,
        left: "10%",
        width: 5,
        height: 5,
    },
    tech: {
        position: "relative",
        padding: "130px 0",
        backgroundColor: gray,
        "@media(max-width: 767px)": {
            textAlign: "center",
        }
    },
    techStar1: {
        bottom: 50,
        left: "40%",
        width: 20,
        height: 20,
        backgroundColor: "#fff"
    },
    techStar2: {
        bottom: 100,
        left: "50%",
        width: 10,
        height: 10,
        backgroundColor: "#fff"
    },
    techStar3: {
        top: 100,
        right: "20%",
        width: 10,
        height: 10,
        backgroundColor: "#fff"
    },
    techItem: {
        display: "flex",
        maxHeight: 530,
        marginTop: 25,
        padding: 24,
        fontSize: 17,
        fontWeight: 400,
        color: darkGray,
        textAlign: "left",
        backgroundColor: "#dce3dd",
        borderRadius: 8,
        overflow: "hidden",
        cursor: "pointer",
        transition: "max-height 0.25s, backgroundColor 0.25s",
    },
    techItemClosed: {
        maxHeight: 78,
        backgroundColor: "#fff",
    },
    techItemIcon: {
        display: "block",
        minWidth: 24,
        marginTop: 3,
        marginRight: 20,
        flexGrow: 0,
    },
    techItemContent: {
        flexGrow: 1,
    },
    techItemTitle: {
        fontSize: 20,
        fontWeight: 700,
        color: black,
    },
    techItemText: {
        margin: "10px 0 0 0",
        opacity: 1,
        transition: "opacity 0.25s",
    },
    techItemTextClosed: {
        opacity: 0,
    },
    title: {
        position: "relative",
        margin: "0 0 25px 0",
        fontSize: 28,
        fontWeight: 700,
        color: black,
        zIndex: 2,
    },
    text: {
        position: "relative",
        margin: "0 0 40px 0",
        fontSize: 17,
        lineHeight: "27px",
        color: darkGray,
        zIndex: 2,
    },
    img: {
        display: "block",
        maxWidth: "100%",
    },
    works: {
        padding: "80px 0",
    },
    workTitle: {
        marginBottom: 25,
        "@media(max-width: 767px)": {
            textAlign: "center",
        }
    },
    worksText: {
        marginTop: 24,
        marginRight: 100,
        "@media(max-width: 400px)": {
            marginRight: 0,
        }
    },
    workItem: {
        position: "relative",
        width: "100%",
        maxWidth: 500,
        height: 450,
        margin: "0 auto 110px auto",
        padding: "40px 25px",
        overflow: "hidden",
        backgroundColor: "rgba(240, 240, 240, 0.3)",
        borderRadius: 8,
        cursor: "pointer",
        ":hover": {
            boxShadow: "10px 30px 50px rgba(60, 88, 168, 0.2)",
            ":nth-child(1n) .works-bg": {
                bottom: 0,
                right: 0,
            }
        },
    },
    workItemBg: {
        position: "absolute",
        width: "100%",
        bottom: -10,
        right: -10,
        transition: "bottom 0.2s, right 0.2s",
    },
    workItemLast: {
        marginBottom: 0,
    },
    workItemTop: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    workItemTitle: {
        margin: 0,
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: 24,
        fontWeight: 700,
        color: black,
        textShadow: "1px 1px 1px #fafafa",
        textDecoration: "none",
    },
});