import * as runtypes from 'runtypes';

// RUNTIME
export const ConsoleUser = runtypes.Record({
    id:                     runtypes.Number,
    created_on:             runtypes.Number,
    primary_email:          runtypes.String,
    is_client:              runtypes.Boolean,
    can_view_console:       runtypes.Boolean,
    can_manage_users:       runtypes.Boolean,
    can_manage_projects:    runtypes.Boolean,
    can_run_deploy:         runtypes.Boolean,
    client_project:         runtypes.Record({
        id: runtypes.Number,
        title: runtypes.String,
    }).Or(runtypes.Null)
});
export const ConsoleUsers = runtypes.Array(ConsoleUser)

export type ConsoleUser = runtypes.Static<typeof ConsoleUser>
export type ConsoleUsers = runtypes.Static<typeof ConsoleUsers>