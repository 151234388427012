import React, { useState } from 'react';
// models
import { PropsType } from './model';
// components
import { Content, Footer } from 'src/view/components/lightbox/lightbox.react';
import { SendResetPasswordEmail } from 'src/runtime/session';
import { ErrorResponse } from 'src/utils/api';
import LoadButton from 'src/view/components/buttons/LoadButton.react';
// sryles
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import { css } from 'aphrodite';
import Styles from './login.css';


export default function({SetPage}:PropsType) {
    const [ email, setEmail ] = useState("");
    const [ emailError, setEmailError ] = useState<boolean>(false);
    const [ err, setErr ] = useState<ErrorResponse<any> | null>(null);
    const [ IsSending, SetIsSending ] = useState(false);

    function onChageEmail(e:React.FormEvent<HTMLInputElement>) {
        setEmail(e.currentTarget.value)
        if (emailError) setEmailError(false);
        if (err) setErr(null);
    }

    function onKeyPress(e:React.KeyboardEvent) {
        if(e.key === 'Enter'){
            e.preventDefault();
            onClickContinue();
        }
    }

    async function onClickContinue(e?:React.MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        if (email.trim() === '') {
            setEmailError(true);
            return null;
        }

        SetIsSending(true);

        const err = await SendResetPasswordEmail(email);
        
        SetIsSending(false);
        if (err != null) {
            setErr(err)
            return
        }

        SetPage("reset-pass-confirm")
        return
    }

    function setLogin(e?:React.MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        SetPage("sign-in");
    }

    return (
        <form className={ css(Styles.form) }>
            <Content styles={ Styles.inputs }>
                <input {...{
                    onChange:       onChageEmail,
                    placeholder:    "Email",
                    type:           "email",
                    autoComplete:   "Email",
                    onKeyPress,
                    className:      css(Styles.input, Styles.inputMargin, emailError && Styles.inputError)
                }} />
                {err !== null ? <section className={css(Styles.error)}>{ err.status_text }</section> : null }
            </Content>
            <Footer styles={Styles.bottom}>
                <LoadButton {...{
                    type: "button",
                    text: "Reset pasword",
                    styleConfig: {
                        color:          SimpleButton.colors.blue,
                        uppercase:      true,
                        fontSize:       "0.75rem",
                        fontWeight:     "bold",
                        height:         45,
                        borderRadius:   3,
                    },
                    onClick: onClickContinue,
                    loading: IsSending,
                }}/>
                <button type="button" className={css(Styles.create)} onClick={setLogin}>Go to Sign in</button>
            </Footer>
        </form>
    )
}