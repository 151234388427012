import React from 'react';
// components
import HeaderReact from 'src/view/components/header/header.react';
import FooterReact from 'src/view/components/footer/footer.react';
// styles
import { css } from 'aphrodite';
import Styles from './page404.css';

export default function() {
    return (
        <article className={ css(Styles.page) }>
            <HeaderReact />
            <section className={ css(Styles.error) }>
                <h1 className={css(Styles.title)}>
                    <span className={css(Styles.titleText)}>404: There is no such page</span>
                </h1>
            </section>
            <FooterReact />
        </article>
    );
}