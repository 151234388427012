import { post } from 'src/utils/api';

export default async function (id: number, should_stop: boolean): Promise<boolean> {
    const [res, err] = await post({
        url: `/console/products/${id}/servers`,
        body: {
            should_stop,
        }
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    return res;
}