import EventEmitter from 'eventemitter3';
const Events = new EventEmitter()

export type GrowlType = "top-line" | null;

export function ShowGrowl(type:GrowlType) {
    Events.emit("show-growl", type);
}

export function listenOnShow(func:(type:GrowlType) => void) {
    Events.addListener("show-growl", func)
}

export function unlistenOnShow(func:(type:GrowlType) => void) {
    Events.removeListener("show-growl", func)
}

export function HideGrowl() {
    Events.emit("hide-growl");
}

export function listenOnHide(func:() => void) {
    Events.addListener("hide-growl", func)
}

export function unlistenOnHide(func:() => void) {
    Events.removeListener("hide-growl", func)
}

export function ChangeGrowl(progress:number) {
    Events.emit("hide-growl", progress);
}

export function listenOnChange(func:(progress:number) => void) {
    Events.addListener("change-growl", func)
}

export function unlistenOnChange(func:(progress:number) => void) {
    Events.removeListener("change-growl", func)
}