import React, { useEffect, useRef, useState} from 'react';
import { css } from 'aphrodite';
import Styles from './SimpleDropDown.scss';

interface props {
    Element: React.ComponentType,
    DropElement: React.ComponentType,
    position?: string,
}

export default function ({
    Element,
    DropElement,
    position,
}: props) {
    const wrapper = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        function onClickOverlay(e: MouseEvent) {
            if (show) {
                if (wrapper.current && e.target instanceof HTMLElement) {
                    if (!wrapper.current.contains(e.target)) {
                        setShow(false);
                    }
                }
            }
        };

        window.addEventListener('click', onClickOverlay)
        return () => {
            window.removeEventListener('click', onClickOverlay)
        }
    }, [show])

    return (
        <section ref={wrapper} className={css(Styles.dropdownOnClick)} onClick={() => setShow(!show)}>
            <Element />
            <aside className={css(Styles.dropdownAside, show && Styles.dropdownAsideOpened, (typeof position !== 'undefined' && position === 'right') && Styles.dropdownAsideRight)}>
                <DropElement />
            </aside>
        </section>
    );
}