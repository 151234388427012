import React from 'react';
import { history } from 'src/utils/history';
import Login from './login.react';
import { css, StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
    page: {
        display: "flex",
        justifyContent: "center",
    }
})

export default function() {
    return (
        <div {...{
            className: css(styles.page)
        }}>
            <Login selector="login" close={ () => { history.push("/") } } content />
        </div>
    );
}