import { StyleSheet } from 'aphrodite';
import { black, gray, yellow } from 'src/styles/colors';

export default StyleSheet.create({
    header: {
        width: "100%",
        backgroundColor: "#fff",
    },
    container: {
        borderBottom: "1px solid rgba(2, 41, 28, 0.1)"
    },
    content: {
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    logo: {
        display: "block",
        fill: black,
    },
    menu: {
        display: "flex",
        alignItems: "center",
    },
    menuItem: {
        listStyle: "none",
    },
    menuLink: {
        marginRight: 5,
        padding: "10px 20px",
        fontSize: 17,
        fontWeight: 600,
        color: black,
        textDecoration: "none",
        borderRadius: 8,
        backgroundColor: "transparent",
        cursor: "pointer",
        transition: "background-color 0.2s",
        ':hover': {
            backgroundColor: yellow,
        },
        "@media(max-width: 680px)": {
            display: "none",
        }
    },
    contactLink: {
        marginRight: 0,
        backgroundColor: gray,
        border: `1px solid ${gray}`,
        transition: "background-color 0.2s, border 0.2s",
        ":hover": {
            backgroundColor: "transparent",
            border: `1px solid ${black}`,
        }
    },
    menuMobile: {
        display: "none",
        cursor: "pointer",
        "@media(max-width: 680px)": {
            display: "block",
        }
    },
    menuMobileIcon: {
        display: "block",
        width: 36,
        height: 36,
        fill: black,
    },
    mobileMenuBlock: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        width: 300,
        padding: "21px 25px 50px 25px",
        backgroundColor: "#000",
        transform: "translate(330px, 0px)",
        zIndex: 1297,
        transition: "transform 0.2s ease-in-out",
    },
    mobileMenuBlockVisible: {
        transform: "translate(0px)",
    },
    mobileMenuList: {
        display: "block",
        width: "100%",
        marginTop: 30,
    },
    mobileMenuLink: {
        display: "block",
        margin: "3px 0",
        padding: "5px 0",
        color: "#fff",
        borderRadius: 5,
        textDecoration: "none",
        cursor: "pointer",
        ':hover': {
            textDecoration: "underline",
        },
    },
    menuClose: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 15,
        right: 15,
        width: 30,
        height: 30,
        borderRadius: 3,
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(196, 196, 196, 0.2)",
        }
    },
    menuCloseIcon: {
        display: "block",
        fill: "#fff"
    },
})