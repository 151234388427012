import React, { useEffect, useState } from 'react';
import { Lightbox, Header, Body, Content, Footer } from 'src/view/components/lightbox/lightbox.react';
import { ComponentProps } from 'src/view/components/lightbox/lightbox.model';
import LoadButton from 'src/view/components/buttons/LoadButton.react';
import AddUserAPI from 'src/api/SaveUserSettings';
import { css, StyleSheet } from 'aphrodite';
import SimpleDropDownReact from 'src/view/components/dropdowns/SimpleDropDown.react';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import { Project } from 'src/models/Projects';
import GetProjects from 'src/api/GetProjects';
import { ConsoleUser } from 'src/models/ConsoleUser';

export default function UserSettings({selector, close, content}: ComponentProps<{
    OnAdd:      () => void,
    prefill?:   ConsoleUser,
}>) {
    const [ Projects, SetProjects ] = useState<Project[]>([]);
    const [ SelectedProjects, SetSelectedProjects ] = useState<Project | null>(null);
    const [ Loading, SetLoading ] = useState(false);
    const [ Email, SetEmail ] = useState(content.prefill?.primary_email || "");
    const [ Password, SetPassword ] = useState("");
    const [ IsClient, SetIsClient ] = useState(content.prefill?.is_client || false);
    const [ CanViewConsole, SetCanViewConsole ] = useState(content.prefill?.can_view_console || false);
    const [ CanManageUsers, SetCanManageUsers ] = useState(content.prefill?.can_manage_users || false);
    const [ CanManageProjects, SetCanManageProjects ] = useState(content.prefill?.can_manage_projects || false);
    const [ CanRunDeploy, SetCanRunDeploy ] = useState(content.prefill?.can_run_deploy || false);

    async function AddNewUser() {
        if (Email.length < 1) {
            alert("Email is required");
        } else if (!content.prefill && Password.length < 6) {
            alert("Password is required and must be more then 6 chars")
        } else if (IsClient && SelectedProjects === null) {
            alert("Please select project");
        } else {
            SetLoading(true);
            const err = await AddUserAPI(
                Email,
                Password,
                IsClient,
                SelectedProjects ? SelectedProjects.id : 0,
                CanViewConsole,
                CanManageUsers,
                CanManageProjects,
                CanRunDeploy
            );
            SetLoading(false);

            if (err === null) {
                content.OnAdd();
                close();
            } else {
                alert(err);
            }
        }
    };

    useEffect(() => {
        async function load() {
            try {
                const res = await GetProjects();
                SetProjects(res);
                if (content.prefill && content.prefill.client_project) {
                    const project = res.find(p => p.id === content.prefill?.client_project?.id);
                    if (project) {
                        SetSelectedProjects(project);
                    }
                }
            } catch (error) {
                alert(error);
            }
        }

        load();
    }, [ content.prefill ]);

    return (
        <Lightbox {...{ close }}>
            <Header {...{ selector, close }}>
                { content.prefill ? "Edit " : "New " } User Settings
            </Header>
            <Body>
                <Content>
                    <div className={ css(styles.form) }>
                        <input {...{
                            placeholder:    "user email",
                            value:          Email,
                            disabled:       content.prefill ? true : false,
                            className:      css(styles.input),
                            onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                                SetEmail(e.target.value);
                            }
                        }} />
                        <input {...{
                            placeholder:    "password",
                            value:          Password,
                            className:      css(styles.input),
                            onChange:       (e:React.ChangeEvent<HTMLInputElement>) => {
                                SetPassword(e.target.value);
                            }
                        }} />
                        { content.prefill && (
                            <label className={ css(styles.label) }>
                                you can leave password empty if you don't wanna change it
                            </label>
                        ) }
                        <div className={ css(styles.client_select) }>
                            <div className={ css(styles.cb) }>
                                <input {...{
                                    id:         "is_client",
                                    type:       "checkbox",
                                    name:       "is_client",
                                    checked:    IsClient,
                                    onChange:   (e:React.ChangeEvent<HTMLInputElement>) => {
                                        SetIsClient(!IsClient);
                                    }
                                }} />
                                <label htmlFor="is_client" className={ css(styles.cb_label) }>
                                    Is Client
                                    { IsClient && " for" }
                                </label>
                            </div>
                            { IsClient && (
                                <SimpleDropDownReact {...{
                                    event: "click",
                                    Element: (
                                        <input {...{
                                            value:      SelectedProjects ? SelectedProjects.name : "click to select project",
                                            className:  css(
                                                SelectedProjects && styles.select_input_bold,
                                                styles.select_input
                                            ),
                                            disabled:   true,
                                        }} />
                                    ),
                                    DropElement: (
                                        <ul className={ css(styles.projects) }>
                                            { Projects.map(project => (
                                                <li {...{
                                                    key: project.id,
                                                    className: css(styles.project),
                                                    onClick: () => {
                                                        SetSelectedProjects(project);
                                                    }
                                                }} >
                                                    { project.name }
                                                </li>
                                            )) }
                                        </ul>
                                    )
                                }} />
                            ) }
                        </div>
                        <h2 className={ css(styles.admin_access) }>
                            Admin access
                        </h2>
                        <div className={ css(styles.admin_flags) }>
                            <div className={ css(styles.cb) }>
                                <input {...{
                                    id:         "can_view_console",
                                    type:       "checkbox",
                                    name:       "can_view_console",
                                    checked:    CanViewConsole,
                                    onChange:   (e:React.ChangeEvent<HTMLInputElement>) => {
                                        SetCanViewConsole(!CanViewConsole);
                                    }
                                }} />
                                <label htmlFor="can_view_console" className={ css(styles.cb_label) }>Can view console</label>
                            </div>
                            <div className={ css(styles.cb) }>
                                <input {...{
                                    id:         "can_manage_users",
                                    type:       "checkbox",
                                    name:       "can_manage_users",
                                    checked:    CanManageUsers,
                                    onChange:   (e:React.ChangeEvent<HTMLInputElement>) => {
                                        SetCanManageUsers(!CanManageUsers);
                                    }
                                }} />
                                <label htmlFor="can_manage_users" className={ css(styles.cb_label) }>Can manage users</label>
                            </div>
                            <div className={ css(styles.cb) }>
                                <input {...{
                                    id:         "can_manage_projects",
                                    type:       "checkbox",
                                    name:       "can_manage_projects",
                                    checked:    CanManageProjects,
                                    onChange:   (e:React.ChangeEvent<HTMLInputElement>) => {
                                        SetCanManageProjects(!CanManageProjects);
                                    }
                                }} />
                                <label htmlFor="can_manage_projects" className={ css(styles.cb_label) }>Can manage projects</label>
                            </div>
                            <div className={ css(styles.cb) }>
                                <input {...{
                                    id:         "can_run_deploy",
                                    type:       "checkbox",
                                    name:       "can_run_deploy",
                                    checked:    CanRunDeploy,
                                    onChange:   (e:React.ChangeEvent<HTMLInputElement>) => {
                                        SetCanRunDeploy(!CanRunDeploy);
                                    }
                                }} />
                                <label htmlFor="can_run_deploy" className={ css(styles.cb_label) }>Can run deploy</label>
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer styles={ styles.footer }>
                    <button {...{
                        type: "button",
                        className: css(SimpleButton.button({
                            color: SimpleButton.colors.default,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                        }), styles.footer_btn),
                        onClick: close,
                    }}>
                        Cancel
                    </button>
                    <LoadButton {...{
                        type: "button",
                        text: 'SAVE',
                        styleConfig: {
                            color: SimpleButton.colors.blue,
                            uppercase: true,
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            height: 40,
                            borderRadius: 3,
                            width: 90,
                            justifyContent: "center",
                        },
                        loading: Loading,
                        onClick: AddNewUser,
                    }} />
                </Footer>
            </Body>
        </Lightbox>
    );
}

const styles = StyleSheet.create({
    form: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
    },
    footer_btn: {
        marginRight: "10px",
    },
    input: {
        margin: "10px 0",
        padding: "7px 10px",
        borderRadius: 5,
        border: "1px solid darkgray",
    },
    label: {
        fontSize: 12,
        marginBottom: 10,
    },
    cb: {
        margin: "5px 0",
        flexGrow: 1,
    },
    cb_label: {
        userSelect: "none",
        cursor: "pointer",
        paddingLeft: 5,
    },
    admin_access: {
        fontSize: 13,
        marginTop: 15,
    },
    admin_flags: {
        marginLeft: 10,
    },
    client_select: {
        display: "flex",
        alignItems: "center",
    },
    select_input: {
        width: 300,
        padding: 5,
        borderRadius: 5,
        border: "1px solid darkgray",
        userSelect: "none",
    },
    select_input_bold: {
        fontWeight: "bold",
    },
    projects: {
        margin: 0,
        padding: "10px 0",
        listStyle: "none",
    },
    project: {
        padding: 5,
        cursor: "pointer",
        userSelect: "none",
        fontSize: 12,
        fontWeight: "bold",
        ":hover": {
            background: "#0C6DFF",
            color: "white",
        }
    }
})