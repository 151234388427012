import { useState, useEffect, useRef } from 'react';

export default function useMaxWidth(width: number):boolean {
    const mql = useRef(window.matchMedia(`(max-width: ${width}px)`));
    const [ IsPassed, SetIsPassed ] = useState(mql.current.matches);

    function OnChange(e: MediaQueryListEvent) {
        SetIsPassed(e.matches);
    }

    useEffect(() => {
        let mq = mql.current;
         
        mq.addEventListener("change", OnChange);
        return () => {
            mq.removeEventListener("change", OnChange);
        };
    });

    return IsPassed;
}