import { post } from 'src/utils/api';

export default async function (id: number, branch: string): Promise<boolean> {
    const [res, err] = await post({
        url: `/console/products/${id}/deploys`,
        body: {
            branch,
        }
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    return res;
}