import { put, ErrorResponse } from 'src/utils/api';

export default async function(code: string, password: string):Promise<ErrorResponse<any> | null> {
    const [ , err ] = await put({ 
        url: "session/passwords",
        body: {
            code,
            password,
        }
    });

    return err
}