import React, { ComponentType } from 'react';
import { Lightbox, Header, Body, Content, Footer, ComponentProps, showLightbox } from './lightbox.react';
//styles
import { css } from 'aphrodite';
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import Styles from './warning.css';
import { config } from 'process';

interface config {
    guid?:              string
    style?:             "dark" | "light",
    button?:            ComponentType | JSX.Element | string,
    secondButton?:      ComponentType | JSX.Element | string,
    CutomButtons?:      Array<ComponentType | JSX.Element | string>,
    title?:             string,
    hideMainButton?:    boolean,
    buttonClick?:       () => void,
    secondButtonClick?: () => void,
}

type props = config & {
    body:       ComponentType | JSX.Element | string,
}

export default function(Component: ComponentType | JSX.Element | string, config?: config) {
    showLightbox<props>({
        selector:   config?.guid ? config.guid : Math.random().toString(),
        Component:  Warning,
        content:    {
            ...config,
            body: Component,
        }
    })
}

function Warning({
    selector,
    content,
    close,
}:ComponentProps<props>) {
    const Componet = content.body;
    const Button = content.button || "close";
    const SecondButton = content.secondButton;

    function onClickButton() {
        close();
        if (content.buttonClick) {
            content.buttonClick()
        }
    }

    function onClickSecondButton() {
        close();
        if (content.secondButtonClick) {
            content.secondButtonClick();
        }
    }

    return (
        <Lightbox {...{ close, light: content.style !== "dark" }}>
            <Header {...{ selector, light: content.style !== "dark" }}>
                { content.title || Warning }
            </Header>
            <Body>
                <Content>
                    { typeof Componet === "function" ? (
                        <Componet />
                    ) : typeof Componet === "object" ? (
                        { Componet }
                    ) : Componet }
                </Content>
                <Footer {...{ light: content.style !== "dark", styles: Styles.footer }}>
                    { !content.hideMainButton && (
                        <button {...{
                            type: "button",
                            className:          css(SimpleButton.button({
                                width:          "140px",
                                fontSize:       "1rem",
                                color:          SimpleButton.colors.red,
                                height:         40,
                                borderRadius:   6,
                                withShadow:     true,
                                uppercase:      true,
                                fontWeight:     "normal",
                            })),
                            onClick: onClickButton,
                        }}>
                            { typeof Button === "function" ? (
                                <Button />
                            ) : typeof Button === "object" ? (
                                { Button }
                            ) : Button }
                        </button>
                    )}
                    { content.CutomButtons && content.CutomButtons.map((Button, i) => {
                        if (typeof Button === "function") {
                            return <Button key={i} />
                        } else if (typeof Button === "object") {
                            return Button;
                        } else {
                            return Button;
                        }
                    }) }
                    { SecondButton ? 
                        <button {...{
                            type: "button",
                            className:          css(SimpleButton.button({
                                width:          "140px",
                                fontSize:       "1rem",
                                color:          SimpleButton.colors.blue,
                                height:         40,
                                borderRadius:   6,
                                withShadow:     true,
                                uppercase:      true,
                                fontWeight:     "normal",
                            }), Styles.secondButton),
                            onClick: onClickSecondButton,
                        }}>
                            { typeof SecondButton === "function" ? (
                                <SecondButton />
                            ) : typeof SecondButton === "object" ? (
                                { SecondButton }
                            ) : SecondButton }
                        </button>
                    : null}
                </Footer>
            </Body>
        </Lightbox>
    )
}