import { StyleSheet } from 'aphrodite';
import { black, coralRed, yellow } from './colors';

export default StyleSheet.create({
    base: {
        display: "block",
        width: "100%",
        height: 49,
        padding: "0 17px",
        fontFamily: "'Poppins', sans-serif",
        fontSize: 17,
        fontWeight: 400,
        color: black,
        backgroundColor: "#fff",
        border: "1px solid #DCE3DD",
        borderRadius: 8,
        boxShadow: 0,
        outline: 0,
        flexGrow: 1,
        transition: 'border  .15s ease-in-out',
        '::-webkit-input-placeholder': {
            color: black,
            opacity: 0.3,
        },
        ':-moz-placeholder': {
            color: black,
            opacity: 0.3,
        },
        '::-moz-placeholder': {
            color: black,
            opacity: 0.3,
        },
        ':-ms-input-placeholder': {
            color: black,
            opacity: 0.3,
        },
        ':focus': {
            outline: 0,
            boxShadow: 0,
        }
    },
    withIcon: {
        paddingLeft: 40,
    },
    inputError: {
        border: `1px solid ${coralRed}`,
        ':focus': {
            border: `1px solid ${coralRed}`,
        }
    },
    block: {
        position: "relative",
        width: "100%",
        margin: "0 0 25px 0",
        textAlign: "left",
    },
    inputBlock: {
        position: "relative",
    },
    icon: {
        position: "absolute",
        top: 15,
        left: 17,
        width: 20,
        height: 20,
    },
    label: {
        display: "block",
        marginBottom: 5,
        fontSize: 17,
        fontWeight: 800,
        color: black,
    },
    checkbox: {
        position: "relative",
        display: "flex",
        marginBottom: 5,
        cursor: "pointer",
    },
    checkboxInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        opacity: 0,
        padding: 0,
        cursor: 'inherit',
        zIndex: 1,
    },
    checkboxIconContainer: {
        position: 'relative',
        width: 24,
        minWidth: 24,
        height: 24,
        marginRight: 10,
        backgroundColor: "#fff",
        boxShadow: "0px 7px 20px rgba(0, 0, 0, 0.07)",
        borderRadius: 6,
    },
    checkboxIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-5px',
        marginLeft: '-5px',
        fill: yellow,
        zIndex: 2,
    },
    req: {
        color: coralRed,
    }
})