import * as colors from "src/styles/colors"

export interface Color {
    background:         string,
    color:              string,
    borderColor:        string,
    hoverBackground:    string,
    hoverColor:         string,
    hoverBorderColor:   string,
    activeBackground:   string,
    activeColor:        string,
    activeBorderColor:  string,
}

type names = "green" | "darkGray" | "red" | "blue" | "lightBlue" | "crunchyBlue" | "default" | "black";

export const Colors:Record<names, Color> = {
    green: {
        background:             colors.salad,
        color:                  "white",
        borderColor:            colors.salad,
        hoverBackground:        colors.darkerSalad,
        hoverColor:             "white",
        hoverBorderColor:       colors.darkerSalad,
        activeBackground:       colors.darkenSalad,
        activeColor:            "white",
        activeBorderColor:      colors.darkenSalad,
    },
    darkGray: {
        background:             colors.darkGray,
        color:                  "white",
        borderColor:            colors.darkGray,
        hoverBackground:        colors.darkGray,
        hoverColor:             "white",
        hoverBorderColor:       colors.darkGray,
        activeBackground:       colors.darkenGray,
        activeColor:            "white",
        activeBorderColor:      colors.darkenGray,
    },
    black: {
        background:             colors.black,
        color:                  "white",
        borderColor:            colors.black,
        hoverBackground:        colors.black,
        hoverColor:             "white",
        hoverBorderColor:       colors.black,
        activeBackground:       colors.black,
        activeColor:            "white",
        activeBorderColor:      colors.black,
    },
    red: {
        background:             colors.coralRed,
        color:                  "white",
        borderColor:            colors.coralRed,
        hoverBackground:        colors.coralRed,
        hoverColor:             "white",
        hoverBorderColor:       colors.coralRed,
        activeBackground:       colors.coralRed,
        activeColor:            "white",
        activeBorderColor:      colors.darkenColarRed,
    },
    blue: {
        background:             colors.brandBlue,
        color:                  "white",
        borderColor:            colors.brandBlue,
        hoverBackground:        colors.brandBlue,
        hoverColor:             "white",
        hoverBorderColor:       colors.brandBlue,
        activeBackground:       colors.brandBlue,
        activeColor:            "white",
        activeBorderColor:      colors.darkenBrandBlue,
    },
    lightBlue: {
        background:             colors.lightBlue,
        color:                  "white",
        borderColor:            colors.lightBlue,
        hoverBackground:        colors.lightBlue,
        hoverColor:             "white",
        hoverBorderColor:       colors.lightBlue,
        activeBackground:       colors.lightBlue,
        activeColor:            "white",
        activeBorderColor:      colors.brandBlue,
    },
    crunchyBlue: {
        background:             colors.crunchyBlue,
        color:                  "white",
        borderColor:            colors.crunchyBlue,
        hoverBackground:        colors.crunchyBlue,
        hoverColor:             "white",
        hoverBorderColor:       colors.crunchyBlue,
        activeBackground:       colors.crunchyBlue,
        activeColor:            "white",
        activeBorderColor:      colors.lightBlue,
    },
    default: {
        background:             "transparent",
        color:                  colors.darkGray,
        borderColor:            colors.darkGray,
        hoverBackground:        "transparent",
        hoverColor:             colors.darkenGray,
        hoverBorderColor:       colors.darkenGray,
        activeBackground:       "transparent",
        activeColor:            colors.darkenGray,
        activeBorderColor:      colors.darkenGray,
    }
}