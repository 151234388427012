import { StyleSheet, StyleDeclaration } from 'aphrodite';
import { Color, Colors } from './Colors.scss';

const base:StyleDeclaration = {
    display:                    "inline-block",
    position:                   "relative",
    textAlign:                  "center",
    whiteSpace:                 "nowrap",
    border:                     "1px solid transparent",
    outline:                    0,
    verticalAlign:              "middle",
    transition:                 "background 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out",
    userSelect:                 "none",
    overflow:                   "hidden",
}

export interface Config {
    color?:             Color,
    fontSize?:          number | string,
    height?:            number | string,
    width?:             number | string,
    lineHeight?:        number | string,
    justifyContent?:    string,
    withShadow?:        boolean,
    boxShadow?:         string,
    borderRadius?:      number | string,
    horizontalPadding?: number,
    withBorder?:        boolean,
    clickable?:         boolean,
    uppercase?:         boolean,
    fontWeight?:        string,
}

export function verifyConfig(cfg: Config):Config {
    return {
        color:              cfg.color || Colors.green,
        fontSize:           cfg.fontSize || 12,
        height:             cfg.height || 36,
        lineHeight:         cfg.lineHeight || cfg.height || 36,
        withShadow:         cfg.withShadow || false,
        borderRadius:       typeof cfg.borderRadius != "undefined" ? cfg.borderRadius : 3,
        horizontalPadding:  cfg.horizontalPadding || 10,
        justifyContent:     cfg.justifyContent || "normal",
        width:              cfg.width || "auto",
        withBorder:         typeof cfg.withBorder == "undefined" ? true : cfg.withBorder,
        clickable:          typeof cfg.clickable == "undefined" ? true : cfg.clickable,
        uppercase:          typeof cfg.uppercase == "undefined" ? true : cfg.uppercase,
        fontWeight:         typeof cfg.fontWeight == "undefined" ? "normal" : cfg.fontWeight,
    }
}

export default {
    colors: Colors,
    icon: {
        after: {

        },
    },
    button: (cfg: Config) => {
        const config:Config = verifyConfig(cfg);

        const extra:StyleDeclaration = {
            height:             config.height,
            width:              config.width,
            lineHeight:         `${config.lineHeight}px`,
            fontFamily:         "'Poppins', sans-serif",
            fontSize:           config.fontSize,
            fontWeight:         config.fontWeight,
            borderRadius:       config.borderRadius,
            justifyContent:     config.justifyContent,
            padding:            `0px ${config.horizontalPadding}px`,
            color:              config.color?.color,
            background:         config.color?.background,
            borderColor:        config.withBorder ? config.color?.borderColor : "transparent",
            cursor:             config.clickable ? "pointer" : "default",
            textTransform:      config.uppercase ? "uppercase" : "default",
            textDecoration:     "none",
            ':nth-child(1n) svg': {
                fill:   config.color?.color,
            },
            ":hover":       {
                backgroundColor:    config.color?.hoverBackground,
                color:              config.color?.hoverColor,
                borderColor:        config.withBorder ? config.color?.hoverBorderColor : "transparent",
                boxShadow:          config.boxShadow || config.withShadow ? "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)" : undefined,
                ':nth-child(1n) svg': {
                    fill:   config.color?.hoverColor,
                }
            },
            ":active":      {
                backgroundColor:    config.color?.activeBackground,
                color:              config.color?.activeColor,
                borderColor:        config.withBorder ? config.color?.activeBorderColor : "transparent",
                ':nth-child(1n) svg': {
                    fill:   config.color?.activeColor,
                },
            }
        }

        return StyleSheet.create({
            base: {
                ...base,
                ...extra,
            }
        }).base
    },
};