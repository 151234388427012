import * as runtypes from 'runtypes';

export const ProductUser = runtypes.Record({
    email:  runtypes.String,
    role:   runtypes.String,
});
export type ProductUser = runtypes.Static<typeof ProductUser>

export const Product = runtypes.Record({
    id:                 runtypes.Number,
    name:               runtypes.String,
    url:                runtypes.String,
    kind:               runtypes.String,
    is_active:          runtypes.Boolean,
    can_deploy:         runtypes.Boolean, 
    can_stop:           runtypes.Boolean, 
    is_stopped:         runtypes.Boolean,
    can_choose_branch:  runtypes.Boolean, 
    users:              runtypes.Array(ProductUser),
});
export type Product = runtypes.Static<typeof Product>

export const Project = runtypes.Record({
    id:         runtypes.Number,
    name:       runtypes.String,
    contact:    runtypes.Record({
        name:   runtypes.String,
        email:  runtypes.String,
        phone:  runtypes.String,
    }),
    is_active:  runtypes.Boolean,
    products:   runtypes.Array(Product),
});

export type Project = runtypes.Static<typeof Project>

export const DeployStatus = runtypes.Literal("building").Or(runtypes.Literal("deploying").Or(runtypes.Literal("deployed").Or(runtypes.Literal("failed"))));
export type DeployStatus = runtypes.Static<typeof DeployStatus>

export const DeployStatusResult = runtypes.Record({
    branch:         runtypes.String,
    deployed_at:    runtypes.Number,
    status:         DeployStatus
});
export type DeployStatusResult = runtypes.Static<typeof DeployStatusResult>