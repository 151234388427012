import { StyleSheet, StyleDeclaration, CSSProperties } from 'aphrodite';

const gridColumns = 12;

const styles:StyleDeclaration<Record<string, CSSProperties>> = {
    container: {
        position: "relative",
        width: 1060,
        maxWidth: "100%",
        margin: "0 auto",
        padding: "0 30px",
        zIndex: 1,
    },
    row: {
        display: "flex",
        '-ms-flex-wrap': "wrap",
        flexWrap: "wrap",
    },
    rowReverse: {
        flexDirection: "row-reverse",
    },
};

for (let i = 0; i < gridColumns; i++) {
    styles[`col${i+1}`] = {
        position: "relative",
        width: '100%',
        maxWidth: '100%',
        margin: '20px 0',
        padding: "0 10px",
        '@media(min-width: 768px)': {
            '-ms-flex': `0 0 ${(i + 1) / gridColumns * 100}%`,
            flex: `0 0 ${(i + 1) / gridColumns * 100}%`,
            maxWidth: `${(i + 1) / gridColumns * 100}%`,
            margin: 0,
        }
    }
    styles[`offset${i + 1}`] = {
        marginLeft: 0,
        '@media(min-width: 768px)': {
            marginLeft: `${(i + 1) / gridColumns * 100}%`,
        }
    }
}

export default StyleSheet.create(styles);