import { StyleSheet } from 'aphrodite';

export const Styles = StyleSheet.create({
    topLine: {
        position:   "fixed",
        left:       0,
        top:        0,
        right:      0,
        height:     5,
        background: "linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)",
        transition: "transform 0.05s",
        zIndex:     1000,
    }
})

export function TransformLine(percent:number) {
    percent = percent > 100 ? 100 : percent < 0 ? 0 : percent;
    return StyleSheet.create({
        base: {
            transform: `translateX(${percent - 100}%)`,
        }
    }).base;
}