import React, { useState, useRef } from 'react';
import { SignUp, session } from 'src/runtime/session';
import { ErrorResponse } from 'src/utils/api';
// models
import { PropsType } from './model';
// component
import SimpleButton from 'src/view/components/buttons/SimpleButton.scss';
import LoadButton from 'src/view/components/buttons/LoadButton.react';
import { Content, Footer } from 'src/view/components/lightbox/lightbox.react';
// styles
import { css } from 'aphrodite';
import Styles from './login.css';
import { history } from 'src/utils/history';

export default function({SetPage, Page, close}:PropsType) {
    const form = useRef<HTMLFormElement>(null)
    const [ email, setEmail ] = useState<string>("");
    const [ emailError, setEmailError ] = useState<boolean>(false);
    const [ password, setPassword ] = useState<string>("");
    const [ passwordError, setPasswordError ] = useState<boolean>(false);
    const [ err, setErr ] = useState<ErrorResponse<any> | null>(null);
    const [ loading, setLoading ] = useState<boolean>(false);

    function onChageEmail(e:React.FormEvent<HTMLInputElement>) {
        if (err !== null) setErr(null);
        if (emailError) setEmailError(false);
        setEmail(e.currentTarget.value)
    }

    function onChagePass(e:React.FormEvent<HTMLInputElement>) {
        if (err !== null) setErr(null);
        if (emailError) setPasswordError(false);
        setPassword(e.currentTarget.value)
    }

    async function onClickContinue(e:React.FormEvent) {
        e.preventDefault();
        e.persist()
        if (email.trim() === '') {
            setEmailError(true);
            return null;
        }
        if (password.trim() === '') {
            setPasswordError(true);
            return null;
        }
        setLoading(true);
        const err = await SignUp(email, password)
        setLoading(false);
               
        if (err != null) {
            setErr(err)
        } else {
            if (session.User.get("verified")) {
                history.push("/console");
            }
        }
    }

    function setResetPassword(e?:React.MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        SetPage("reset");
    }

    return (
        <form ref={form} id="login" action="/" className={ css(Styles.form) } onSubmit={onClickContinue}>
            <Content styles={ Styles.inputs }>
                <input {...{
                    onChange:       onChageEmail,
                    placeholder:    "Email",
                    type:           "email",
                    name:           "id",
                    value:          email,
                    autoComplete:   "on",
                    className:      css(Styles.input, Styles.inputMargin, emailError && Styles.inputError)
                }} />
                <input {...{
                    onChange:       onChagePass,
                    placeholder:    "Password",
                    type:           "password",
                    name:           "password",
                    value:          password,
                    autoComplete:   "current-password",
                    className:      css(Styles.input, Styles.inputMargin, passwordError && Styles.inputError)
                }} />
                <div className={css(Styles.resetBlock)}>
                    <button type="button" className={css(Styles.reset)} onClick={ setResetPassword }>Forgot password?</button>
                </div>
                {err !== null ? <section className={css(Styles.error)}>{ err.status_text }</section> : null }
            </Content>
            <Footer>
                <div className={css(Styles.bottom)}>
                    <div className={css(Styles.bottomSign)}>
                        <LoadButton {...{
                            type: "submit",
                            text: Page.Marker === 'sign-in' ? "Sign in" : "Create account",
                            styleConfig: {
                                color:          SimpleButton.colors.blue,
                                uppercase:      true,
                                fontSize:       "0.75rem",
                                fontWeight:     "bold",
                                height:         45,
                                borderRadius:   3,
                            },
                            loading: loading,
                        }}/>
                    </div>
                </div>
            </Footer>
        </form>
    )
}