import { del } from 'src/utils/api';

export default async function():Promise<number>{
    const [ , err ] = await del({ 
        url: "session",
    });

    if (err != null) {
        return err.status_code
    }
    
    return 0;
}