import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 10,
    },
    secondButton: {
        marginLeft: 10,
    }
});