import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { session } from 'src/runtime/session';
import path from 'path';
//import clientConfig from 'src/utils/config';

export async function get<R = never, E = never>(cfg:BaseRequest):Promise<[R, ErrorResponse<E> | null, string | null]> {
    return await MakeCall<R, E>({
        ...cfg,
        method: "GET",
    })
}

export async function post<R = never, E = never>(cfg:BaseRequest):Promise<[R, ErrorResponse<E> | null, string | null ]> {
    return await MakeCall<R, E>({
        ...cfg,
        method: "POST",
    })
}

export async function put<R = never, E = never>(cfg:BaseRequest):Promise<[R, ErrorResponse<E> | null, string | null ]> {
    return await MakeCall<R, E>({
        ...cfg,
        method: "PUT",
    })
}

export async function del<R = never, E = never>(cfg:BaseRequest):Promise<[R, ErrorResponse<E> | null, string | null ]> {
    return await MakeCall<R, E>({
        ...cfg,
        method: "DELETE",
    })
}

interface BaseRequest {
    url:        string
    headers?:   Record<string, any>
    query?:     Record<string, any>
    body?:      Record<string, any>
}

interface Request extends BaseRequest {
    method:     "GET" | "POST" | "DELETE" | "PUT"
}

interface Response<R> {
    status_code:    number,
    payload:        R,
}

export interface ErrorResponse<R> {
    status_code:    number,
    payload?:       R,
    status_text:    string,
}

async function MakeCall<R = never, E = never>(cfg:Request):Promise<[R, ErrorResponse<E> | null, string | null]> {
    //const host = clientConfig.server.host ? clientConfig.server.host : document.location.origin;
    const url = `${document.location.origin}${path.join("/api/v1/", cfg.url)}`;

	let headers:Record<string, any> = {
        'x-csrf-token': session.CSRFToken,
    };
    
    if (cfg.headers) {
		headers = {...headers, ...cfg.headers}
	}

    const config:AxiosRequestConfig = {
        method:         cfg.method,
        url,
        headers,
        params:         cfg.query,
        data:           cfg.body,
        validateStatus: status => status >= 200 && status < 300,
    }

    try {
        const res = await axios.request<Response<R>>(config);
        return returnResponse<R, E>(res);
    } catch (error) {
        const r:any = {};
        
        if (typeof error.response != 'undefined') {
            return [ r, {
                    status_code:    error.response.data.status_code,
                    status_text:    error.response.data.status_text,
                    payload:        error.response.data.payload,
            }, null ]
        } else {
            return [ r, {
                status_code:    -1,
                status_text:    error.message,
            }, null ];
        }
    }
}

function returnResponse<R, E>(res:AxiosResponse<Response<R>>):[R, ErrorResponse<E> | null, string | null ] {
    if (
        typeof res.headers["content-type"] != "string" || 
        res.headers["content-type"].toLowerCase() !== "application/json"
    ) {
        const r:any = {};
        return [ r, {
            status_code:    -1,
            status_text:    "response is not json",
        }, null ]
    } else if (typeof res.data.payload === "undefined") {
        const r:any = {};
        return [ r, {
            status_code:    -1,
            status_text:    "response does not contain payload",
        }, null ]
    }

    return [ res.data.payload, null, res.headers["x-csrf-token"] ];
}