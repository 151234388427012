import * as runtypes from 'runtypes';
import { get } from 'src/utils/api';
import { Project } from 'src/models/Projects';

export default async function (): Promise<Project[]> {
    const [res, err] = await get<Project[]>({
        url: "/console/projects",
    });
    if (err != null) {
        throw new Error(err.status_text);
    }

    runtypes.Array(Project).check(res);

    return res;
}