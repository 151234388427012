import { StyleSheet } from 'aphrodite';


export default StyleSheet.create({
    dropdown: {
        position: "relative",
    },
    eventHover: {
        ":hover > .dd-aside": {
            opacity: 1,
            visibility: "visible",
            transition: "opacity 100ms ease",
        }
    },
    dropdownOnClick: {
        position: "relative",
    },
    show: {
        opacity: 1,
        visibility: "visible",
        transition: "opacity 100ms ease",
    },
    dropdownAside: {
        //display: "none",
        visibility: "hidden",
        opacity: 0,
        position: "absolute",
        top: "100%",
        left: 0,
        minWidth: 160,
        borderRadius: 10,
        boxShadow: "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#EDEDED",
        zIndex: 10,
    },
    dropdownAsideOpened: {
        opacity: 1,
        visibility: "visible",
        transition: "opacity 100ms ease",
    },
    dropdownAsideClickOpened: {
        opacity: 1,
        transition: "opacity 100ms ease",
    },
    dropdownAsideRight: {
        left: "auto",
        right: 0,
    },
    dropdownAsideFull: {
        left: 0,
        right: 0,
    },
    dropdownAsideTop: {
        top: "auto",
        bottom: "100%",
    },
});